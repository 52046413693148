import { FC } from 'react';
import SingleImage from '../SingleImage';
import {
  IContentBlock3
} from "shared";

interface IProps {
  content: IContentBlock3
}
const Block3: FC<IProps> = ({ content }) => (
  <div className='block3'>
    <div className='image-content'>
      {content?.decodedData.picture && <SingleImage picture={content.decodedData.picture} />}
    </div>
  </div>
)

export default Block3
