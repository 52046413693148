import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';
import Block5 from './Block5';
import Block6 from './Block6';
import Block7 from './Block7';
import Block8 from './Block8';
export { default as EmbedFrame } from './EmbedFrame';
export { default as SingleImage } from './SingleImage';
export const ContentBlocks = {
  block1: Block1,
  block2: Block2,
  block3: Block3,
  block4: Block4,
  block5: Block5,
  block6: Block6,
  block7: Block7,
  block8: Block8
}
