import { FC } from "react";
import EmbedFrame from '../EmbedFrame';
import {
  IContentBlock5
} from "shared";

interface IProps {
  content: IContentBlock5;
}
const Block5: FC<IProps> = ({ content }) => (
  <div className='block5'>
    <div className='embed-content'>
      {content?.decodedData.embed && <EmbedFrame content={content.decodedData.embed.src}  source={content.decodedData.embed.type} />}
    </div>
  </div>
)

export default Block5
