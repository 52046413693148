import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonInput,
  IonButton
} from "@ionic/react";
import { 
  YokIcon
} from 'shared';
import "./styles.scss";

interface IProps {
  initialQuery?: string;
}
const SearchInput: FC<IProps> = ({
  initialQuery = ''
}) => {
  const [query, setQuery] = useState<string>(initialQuery)
  const history = useHistory();

  const handleKeyPress = (event: any) => {
    setQuery(event.target.value)
    if (event.key === 'Enter') {
      searchIt();
    }
  }

  const searchIt = () => history.push(`/resultados/${query}`);

  return (
    <div className="search-input">
      <IonButton
        className="search-button"
        fill="clear"
        onClick={searchIt}
      >
        <YokIcon icon='xp-search' />
      </IonButton>
      <IonInput
        id='search-input'
        type='search'
        inputmode='text'
        onKeyUp={handleKeyPress}
        value={query}
        placeholder='Introduce el texto que deseas buscar'
        aria-label='Introduce el texto que deseas buscar'
      />  
    </div>
  )
}

export default SearchInput;
