import { FC } from 'react';
import {
  IContentBlock6
} from "shared";

interface IProps {
  content: IContentBlock6
}

const Block6: FC<IProps> = (props) => null

export default Block6
