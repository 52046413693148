import { FC, useRef, useState } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll
} from "@ionic/react";
import { 
  ContentsAPI,
  AdvertisementCard,
  NewsCard,
  NewsCardSkeleton,
  newsSettings,
  IContent,
  IEditor
} from 'shared';
import EditorNoContents from "../EditorNoContents";
import "./styles.scss";


interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface IProps {
  editor: IEditor;
}

const EditorPage: FC<IProps> = ({ editor }) => {
  const page = useRef<number>(0);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState<boolean>(false);
  const [data, setData] = useState<IContent[]>([]);
  const [error, setError] = useState<boolean>(false);

  const getData = async (ev: InfiniteScrollCustomEvent | undefined = undefined) => {
    ContentsAPI.getFeed({
      section: 'news',
      editor_id: [parseInt(editor.id)],
      limit: newsSettings.navigation.rows,
      offset: page.current
    })
      .then((response) => {
        if (Array.isArray(response) && !!response === true) {
          setData([...data, ...(Array.isArray(response) ? response : [])]);
          page.current = page.current + 1;
          if (response.length < newsSettings.navigation.rows)
            setIsInfiniteDisabled(true);
        } else {
          setIsInfiniteDisabled(true);
        }
        if (typeof ev !== "undefined") {
          try {
            ev && ev?.target?.complete()!;
          } catch {
            setIsInfiniteDisabled(true);
          }
        }
      })
      .catch(() => {
        /**
         * If there is an error diring the first data request,
         * user must be redirected to 404
         */
        setError(true);
      });
  };

  if (editor === null || editor === undefined) return null;

  return (
    <section className="editor-contents">
      <AdvertisementCard />
      {error === true &&
        <EditorNoContents editorName={editor.name} />}
      {error === false &&
        <IonGrid fixed>
          <IonRow>
            <IonCol size='12'>
            </IonCol>
            {data.length > 0 &&
              data.map((content, index:number) => (
                <IonCol key={`${content.id}-${index}`}>
                  <NewsCard content={content} />
                </IonCol>
              ))}
          </IonRow>
          <IonInfiniteScroll
            onIonInfinite={getData}
            threshold="50%"
            disabled={isInfiniteDisabled}
          >
            <IonRow>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
                <IonCol key={id}>
                  <NewsCardSkeleton />
                </IonCol>
              ))}
            </IonRow>
          </IonInfiniteScroll>
        </IonGrid>}

      <AdvertisementCard />
    </section>
  );
}

export default EditorPage;
