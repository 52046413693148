import React, { FC } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonBreadcrumbs,
  IonBreadcrumb
} from "@ionic/react";
import {
  Link
} from "react-router-dom";
import {
  ShareMenu,
  AdvertisementCard,
  IContentDetail,
  ContentBlocks
} from "shared";
import ReactTooltip from "react-tooltip";
import "./styles.scss";
import 'shared/components/content/styles.scss';

interface IProps {
  content: IContentDetail
}

const WatchContent: FC<IProps> = ({
  content
}) => {
  return (
    <section className="watch-content">
      <AdvertisementCard />
      <IonGrid fixed>
        <IonRow>
          <IonCol className="space-between" size="12">
            <IonBreadcrumbs className="media">
              <IonBreadcrumb>
                <Link className="navigation-breadcrumb" to="/media/explorar">
                  Media
                </Link>
              </IonBreadcrumb>
              <IonBreadcrumb>
                <Link className="navigation-breadcrumb" to={`/media/${content.header.category_alias}/explorar`}>
                  {content.header.category}
                </Link>
              </IonBreadcrumb>
              <IonBreadcrumb>
                <Link className="navigation-breadcrumb" to={`/media/${content.header.id_product}_${content.header.product_alias}`}>
                  {content.header.product}
                </Link>
              </IonBreadcrumb>
            </IonBreadcrumbs>
            <ShareMenu
              title={content.header.title}
              intro={content.header.intro}
              tags={content.header.tags}
              section='Media'
              dark
            />
          </IonCol>
          <IonCol size="12">
            <h1
              itemProp='headline'
              className='content-title'
            >
              {content.header.title}
            </h1>
          </IonCol>
          <IonCol 
            size="12"
            itemProp='articleBody'
            className='watch-body'
          >
            {content?.body && content.body.map((content, index) => {
              // @ts-ignore
              return React.createElement(ContentBlocks[content.block], {
                key: `${content.block}-${index}`,
                content: content
              })
            })}
          </IonCol>
        </IonRow>
      </IonGrid>
      <AdvertisementCard />
      <ReactTooltip
        backgroundColor="#04010a"
        textColor="#fff"
      />
    </section>
  )
};

export default WatchContent;
