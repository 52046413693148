import ComicsImage from "shared/assets/img/search/Comics.jpg";
import CulturaGeekImage from "shared/assets/img/search/CulturaGeek.jpg";
import EntretenimientoImage from "shared/assets/img/search/Entretenimiento.jpg";
import PeliculasSeriesImage from "shared/assets/img/search/PeliculasSeries.jpg";
import VideoJuegosImage from "shared/assets/img/search/VideoJuegos.jpg";

export interface INewsCategories {
  id_category: string;
  category: string;
  category_url: string;
  category_alias: string;
  id_section: string;
  section: string;
  section_url: string;
  banner?: string
}

export const newsCategories : INewsCategories[] = [
  {
    id_category: "16",
    category: "Cómics",
    category_url: "comics",
    category_alias: "comics",
    id_section: "3",
    section: "News",
    section_url: "news",
    banner: ComicsImage
  },
  {
    id_category: "4",
    category: "Cultura Geek",
    category_url: "cultura-geek",
    category_alias: "cultura-geek",
    id_section: "3",
    section: "News",
    section_url: "news",
    banner: CulturaGeekImage
  },
  {
    id_category: "6",
    category: "Entretenimiento",
    category_url: "entretenimiento",
    category_alias: "entretenimiento",
    id_section: "3",
    section: "News",
    section_url: "news",
    banner: EntretenimientoImage
  },
  {
    id_category: "3",
    category: "Películas y Series",
    category_url: "peliculas-y-series",
    category_alias: "peliculas-y-series",
    id_section: "3",
    section: "News",
    section_url: "news",
    banner: PeliculasSeriesImage
  },
  {
    id_category: "12",
    category: "Vídeo Juegos",
    category_url: "video-juegos",
    category_alias: "video-juegos",
    id_section: "3",
    section: "News",
    section_url: "news",
    banner: VideoJuegosImage
  }
];

export interface InavigationOption {
  type: 'simple' | 'nested';
  name: string;
  url: string;
  label: string;
  values?: InavigationOption[]
}

export const navigationOptions : InavigationOption[] = [ 
  {
    type: 'simple',
    name: 'Inicio',
    url: '/',
    label: 'Ir al inicio'
  },
  {
    type: 'nested',
    name: 'News',
    url: '/news/explorar',
    label: '',
    values: [
      {
        type: 'simple',
        name: 'Cómics',
        url: '/news/cómics',
        label: 'Explora los últimos News de Cómics'
      },
      {
        type: 'simple',
        name: 'Cultura Geek',
        url: '/news/cultura-geek',
        label: 'Explora los últimos News de Cultura Geek'
      },
      {
        type: 'simple',
        name: 'Entretenimiento',
        url: '/news/entretenimiento',
        label: 'Explora los últimos News de Entretenimiento'
      },
      {
        type: 'simple',
        name: 'Películas y Series',
        url: '/news/películas-y-series',
        label: 'Explora los últimos News de Películas y Series'
      },
      {
        type: 'simple',
        name: 'Video Juegos',
        url: '/news/video-juegos',
        label: 'Explora los últimos News de Video Juegos'
      }
    ]
  },
  {
    type: 'simple',
    name: 'Media',
    url: '/media/explorar',
    label: 'Ver todos los programas de Media'
  },
  {
    type: 'simple',
    name: 'TV',
    url: '/tv',
    label: 'Ver transmisión en vivo'
  },
  {
    type: 'simple',
    name: 'Busqueda',
    url: '/busqueda',
    label: 'Ir a la página de búsqueda'
  },
  {
    type: 'simple',
    name: 'Nosotros',
    url: '/nosotros',
    label: 'Ver información acerca de nosotros'
  },
  {
    type: 'simple',
    name: 'Contacto',
    url: '/contacto',
    label: 'Ver formulario de contacto'
  }
];
