import { FC } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react'
import {
  EmbedFrame,
  IStreamResource,
  TEmbeds
} from 'shared'
import './styles.scss'
import 'shared/components/content/styles.scss';

interface IProps {
  resource: IStreamResource
}

const Live: FC<IProps> = ({ 
  resource 
}) =>  (
  <IonGrid fixed>
    <IonRow>
      <IonCol size="12">
        <h1 className="page-title --media">{resource.public_name}</h1>
      </IonCol>
      <IonCol size="12">
        <EmbedFrame content={resource.src} source={resource.embed as TEmbeds} /> 
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default Live
