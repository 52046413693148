import { FC } from "react";
import {
  IContentDetail,
  ChapterCard
} from "shared";
import "./styles.scss";

interface IProps {
  content: IContentDetail
}

const WatchPreview: FC<IProps> = ({
  content
}) => {
  return (
    <section className="watch-preview">
      <p className={["status-alert", ...(content.header.id_status === "2" ? ["por-aprobar"] : []), ...(content.header.id_status === "3" ? ["inhabilitado"] : [])].join(" ")}>
        Contenido {content.header.id_status === "2" && "Por Aprobar"} {content.header.id_status === "3" && "Inhabilitado"}
      </p>
      <ChapterCard chapter={content.header} />
    </section>
  )
};

export default WatchPreview;
