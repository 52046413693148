import { FC } from 'react';
import { IonIcon } from '@ionic/react';

interface IProps {
  icon: string
}
const YokIcon : FC<IProps> = ({ icon, ...props}) => {
  const iconFile = require(`./svg/${icon}.svg`)
  return <IonIcon src={iconFile} {...props} />
}

export default YokIcon
