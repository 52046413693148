import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  IonImg
} from "@ionic/react";
import { 
  IProducts
} from 'shared';
import "./styles.scss";


interface IProps {
  section: "news" | "media";
  category: IProducts;
}
  const SearchCategoryCard: FC<IProps> = ({section, category}) => {
  return (
    <Link 
      className={["search-category-card", section].join(" ")}
      to={section === "news" ? `/news/${category.category_url}` : `/media/${category.category_url}/explorar` }
    >
      <figure>
        {category.category_photo_circled && category.category_photo_circled !== '' && <IonImg src={category.category_photo_circled} />}
        <figcaption>
          <p>{category.category}</p>
        </figcaption>  
      </figure>
    </Link>
  )
}

export default SearchCategoryCard;
