import { createSlice } from "@reduxjs/toolkit";
import { 
  ETabs
} from "mobile/routes";
import { 
  TxhrStatus,
  IProducts
} from "shared";

interface IinitialState {
  currentTab: ETabs | null;
  showReturnButton: boolean;
  newsCategories: IProducts[];
  fetchingNewsCategories: TxhrStatus;
  mediaCategories: IProducts[];
  fetchingMediaCategories: TxhrStatus;
}

export const initialState : IinitialState = {
  currentTab: null,
  showReturnButton: false,
  newsCategories: [],
  fetchingNewsCategories: 'idle',
  mediaCategories: [],
  fetchingMediaCategories: 'idle'
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialState,
  reducers: {
    setCurrentTab: (state: IinitialState, { payload  } : { payload: ETabs | null}) => {
      if (state.currentTab !== payload) state.currentTab = payload;
    },
    setShowReturnButton: (state: IinitialState, { payload }: {payload: boolean}) => {
      if (state.showReturnButton !== payload) state.showReturnButton = payload;
    },
    setFetchingNewsCategories: (state: IinitialState, { payload }: {payload: TxhrStatus}) => {
      if (state.fetchingNewsCategories !== payload) state.fetchingNewsCategories = payload;
    },
    setNewsCategories:(state: IinitialState, { payload }: {payload: IProducts[]}) => {
      state.newsCategories = payload;
    },
    setFetchingMediaCategories: (state: IinitialState, { payload }: {payload: TxhrStatus}) => {
      if (state.fetchingMediaCategories !== payload) state.fetchingMediaCategories = payload;
    },
    setMediaCategories:(state: IinitialState, { payload }: {payload: IProducts[]}) => {
      state.mediaCategories = payload;
    }
  },
  extraReducers: (builder) => {}
});

export const {
  setCurrentTab,
  setShowReturnButton,
  setFetchingNewsCategories,
  setNewsCategories,
  setFetchingMediaCategories,
  setMediaCategories
} = navigationSlice.actions;
export default navigationSlice.reducer;
