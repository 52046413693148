import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonNote,
  IonSpinner,
  useIonToast
} from "@ionic/react";
import { 
  ContentWraper,
  useAsync,
  UserServicesAPI,
  ContactAPI,
  IContactParams,
  setView
} from 'shared';
import "./styles.scss";

interface InputChangeEventDetail {
  value: string | undefined | null;
}

interface IonInputCustomEvent<T> extends CustomEvent<T> {
  detail: T;
  target: HTMLIonInputElement;
}

interface SelectChangeEventDetail<T = any> {
  value: T;
}
interface IonSelectCustomEvent<T> extends CustomEvent<T> {
  detail: T;
  target: HTMLIonSelectElement;
}

interface TextareaChangeEventDetail {
  value?: string | null;
}

interface IonTextareaCustomEvent<T> extends CustomEvent<T> {
  detail: T;
  target: HTMLIonTextareaElement;
}

interface IProps {}

const ContactUsPage: FC<IProps> = () => {
  const [present] = useIonToast();
  const history = useHistory();
  const { execute:getCountries, value:countries} = useAsync(UserServicesAPI.getCountries, {}, false);
  const { execute:getTypes, value:types} = useAsync(ContactAPI.getTypes, {}, false);
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<number>();
  const [type, setType] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const validateEmail = (email: string): boolean => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    ) !== null;
  };

  const validateForm = () => {
    const isInvalid = [
      name,
      lastName,
      country,
      email,
      phone,
      type,
      message
    ].some(value => !!value === false);
    setIsValid(!isInvalid && validateEmail(email || ""));
  }

  const handleChange = (event: IonInputCustomEvent<InputChangeEventDetail> | IonSelectCustomEvent<SelectChangeEventDetail<any>> | IonTextareaCustomEvent<TextareaChangeEventDetail>, callback: Function) => {
    callback(event.target.value);
  } 

  const submit = async () => {
    if (isValid === false) return;

    const payload: IContactParams = {
      country: countries!.filter(item => item.id === country)[0],
      type: types!.filter(item => item.id === type)[0],
      email: email,
      firstName: name,
      lastName: lastName,
      message: message,
      telephone: phone as number
    }
    const sendRequest = await ContactAPI.set(payload);
    console.info('payload', payload);
    if (sendRequest) {
      present({
        message: "Mensaje enviado con exito",
        duration: 3000,
        position: "top",
        color: "success"
      });
      history.push("/");
    } else {
      present({
        message: "Ocurrió un error al enviar el mensaje",
        duration: 3000,
        position: "top",
        color: "danger"
      });
    }
  }

  useEffect(() => {
    validateForm();
  }, [name, lastName, country, email, phone, type, message]);

  useEffect(() => {
    getCountries && getCountries({});
  }, [getCountries]);
  
  useEffect(() => {
    getTypes && getTypes({});
  }, [getTypes]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className='contact-us-page'>
      <IonGrid fixed>
        <IonRow>
          <IonCol size="12" className='align-center'>
            <h1 className="page-title">Contactanos:</h1>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="stacked">Nombre</IonLabel>
              <IonInput value={name} onIonChange={(event) => handleChange(event, setName)} inputmode="text" />
            </IonItem>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="stacked">Apellido</IonLabel>
              <IonInput value={lastName} onIonChange={(event) => handleChange(event, setLastName)} inputmode="text" />
            </IonItem>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="stacked">País</IonLabel>
              <IonSelect
                mode="ios"
                value={country}
                disabled={countries === null}
                onIonChange={(event) => handleChange(event, setCountry)}
                okText="Aceptar"
                cancelText="Cancelar"
                interfaceOptions={{ cssClass: "select-alert"}}
              >
                {countries && countries.map((option) => (
                  <IonSelectOption key={`country-option-${option.id}`} value={option.id}>{option.name}</IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem className={[...(email !== "" && validateEmail(email || "") === false) ? ["ion-invalid"] : [""]].join(" ")}>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput value={email}  onIonChange={(event) => handleChange(event, setEmail)} type="email" inputmode="email" pattern="email" />
              <IonNote slot="error">Ingrese un email válido</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="stacked">Número de Contacto</IonLabel>
              <IonInput value={phone}  onIonChange={(event) => handleChange(event, setPhone)} type="tel" inputmode="tel" />
            </IonItem>
          </IonCol>
          <IonCol size="6" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="stacked">¿En qué podemos servirle?</IonLabel>
              <IonSelect
                mode="ios"
                value={type} 
                disabled={types === null} 
                onIonChange={(event) => handleChange(event, setType)}
                okText="Aceptar"
                cancelText="Cancelar"
                interfaceOptions={{ cssClass: "select-alert"}}
              >
                {types && types.map((option) => (
                  <IonSelectOption key={`type-option-${option.id}`} value={option.id}>{option.type}</IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonItem>
              <IonLabel position="stacked">Mensaje</IonLabel>
              <IonTextarea value={message} onIonChange={(event) => handleChange(event, setMessage)} />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonButton disabled={!isValid || loading} onClick={submit}>
              {loading ? <IonSpinner name="circles" /> : "Enviar"}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </ContentWraper>
  );
}

export default ContactUsPage;
