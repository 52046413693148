import { FC } from 'react';
import {
  IonSkeletonText
} from "@ionic/react";
import './styles.scss';

interface IProps {}


const AdvertisementCardSkeleton: FC<IProps> = () => null;

/*
const AdvertisementCardSkeleton: FC<IProps> = () => (
  <figure className="advertisement-card-skeleton">
    <IonSkeletonText animated={true} />
  </figure>
);
*/

export default AdvertisementCardSkeleton;
