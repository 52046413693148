import React, { FC, useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonAvatar,
  IonBreadcrumbs,
  IonBreadcrumb,
  IonChip,
  IonLabel
} from "@ionic/react";
import {
  Link
} from "react-router-dom";
import {
  ShareMenu,
  AdvertisementCard,
  IContentDetail,
  ContentBlocks
} from "shared";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import "moment/locale/es";
import "./styles.scss";
import 'shared/components/content/styles.scss';
moment.locale("es");

interface IProps {
  content: IContentDetail
}

const ReadContent: FC<IProps> = ({
  content
}) => {
  const [date, setDate] = useState({ fromNow: "", formated: "" })
  useEffect(() => {
    const date = moment(content.header.date, "YYYY-MM-DD HH:mm:ss");
    setDate({
      fromNow: date.locale("es").fromNow(), 
      formated: date.locale("es").format("dddd MMMM yyyy")
    })
  }, [content]);

  return (
    <section className="read-content">
      <AdvertisementCard />
      <IonGrid fixed>
        <IonRow>
          <IonCol className="space-between" size="12">
            <IonBreadcrumbs className="news">
              <IonBreadcrumb>
                <Link className="navigation-breadcrumb" to="/news">
                  News
                </Link>
              </IonBreadcrumb>
              <IonBreadcrumb>
                <Link className="navigation-breadcrumb" to={`/news/${content.header.category_alias}`}>
                  {`${content.header.category}`}
                </Link>
              </IonBreadcrumb>
            </IonBreadcrumbs>
            <ShareMenu
              title={content.header.title}
              intro={content.header.intro}
              tags={content.header.tags}
              section='News'
              dark
            />
          </IonCol>
          <IonCol size="12">
            <h1
              itemProp='headline'
              className='content-title'
            >
              {content.header.title}
            </h1>
          </IonCol>
          <IonCol className="space-between" size="12">
            <Link
              itemProp="author"
              className="editor"
              aria-label={`Ver perfil de ${content.header.editor_name}`}
              to={`/editor/${content.header.id_editor}_${content.header.editor_url_name}`}
            >
              <IonAvatar>
                <IonImg
                  src={content.header.editor_picture}
                  alt={`Foto de perfil de ${content.header.editor_picture}`}
                />
              </IonAvatar>
              {content.header.editor_name}
            </Link>
            <span
              className="date"
              data-tip={date.formated}
            >
              {date.fromNow}
              <meta
                itemProp="datePublished"
                content={date.formated}
              />
            </span>
          </IonCol>
          <IonCol size="12">
            <figure 
              className="img-banner"
              itemProp="image"
              itemScope
              itemType="http://schema.org/ImageObject"
            >
              <meta itemProp="height" content={content.header.miniature_height} />
              <meta itemProp="width" content={content.header.miniature_width} />
              <meta itemProp="url" content={content.header.miniature} />
              <IonImg
                src={content.header.miniature}
                alt={content.header.title}
              />
              
            </figure>
            <figcaption>
              <p
                itemProp='description'
                className='intro'
              >
                {content.header.intro}
              </p>
            </figcaption>
          </IonCol>
          <IonCol 
            size="12"
            itemProp='articleBody'
            className='read-body'
          >
            {content?.body && content.body.map((content, index) => {
              // @ts-ignore
              return React.createElement(ContentBlocks[content.block], {
                key: `${content.block}-${index}`,
                content: content
              })
            })}
          </IonCol>
          <IonCol
            size="12"
            className="tags-container"
          >
            {content?.header?.tags && content.header.tags.map((tag) => (
              <IonChip>
                <Link
                  to={`/news/${tag.alias}`}
                  key={tag.id}
                  className='chip-link news'
                >
                  <IonLabel>{tag.name}</IonLabel>
                </Link>
                
              </IonChip>
            ))}
          </IonCol>
        </IonRow>
      </IonGrid>
      <AdvertisementCard />
      <ReactTooltip
        backgroundColor="#04010a"
        textColor="#fff"
      />
    </section>
  )
};

export default ReadContent;
