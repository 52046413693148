import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { 
  AdvertisementCard,
  useAsync,
  ProductsAPI,
  ContentSwiper,
  ContentSwiperSkeleton,
  ContentWraper,
  setView
} from 'shared';
import './styles.scss';

interface IProps {}

const ExploreMediaPage: FC<IProps> = () => {
  const { execute, status, value, error } = useAsync(ProductsAPI.getFeed, { section: 'media' }, false);
  const history = useHistory();

  useEffect(() => {
    execute && execute({ section: 'media' });
  }, [execute]);

  useEffect(() => {
    if (error !== null ||
        status === 'error' ||
        (status === 'success' && value?.length === 0)) {
      history.push('/404');
    }
  }, [error, status, value]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="explore-media page" metadata="media">
      <AdvertisementCard />

      {status !== 'success' && [1, 2, 3, 4, 5, 6].map((index:number) => (
        <ContentSwiperSkeleton key={index} backgrounded color='media' swiperType='media-cards' />
      ))}

      {status === 'success' && value !== null && value.map((product, index: number) => (
        <ContentSwiper
          key={`${product.category_alias}-${index}`}
          backgrounded
          color='media'
          swiperType='media-cards'
          titleText={product.category}
          titleUnderlined
          navigateToText={`Ver más`}
          navigateToUrl={`/media/${product.category_alias}/explorar`}
          navigateToUnderlined
          defaultData={product.products}
        />
      ))}

      <AdvertisementCard />
    </ContentWraper>
  );
}

export default ExploreMediaPage;
