import { FC, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  ContentWraper,
  ProductsAPI,
  useAsync,
  setMediaMetaTags,
  mediaSettings,
  setView
} from "shared";
import MediaProgramLoading from "./MediaProgramLoading";
import MediaProgramHeader from "./MediaProgramHeader";
import MediaProgramBody from "./MediaProgramBody";
import "./styles.scss";

interface IProps {

}

const MediaProgramPage: FC<IProps> = () => {
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string }>();
  const { execute, status, value, error } = useAsync(ProductsAPI.get, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]);

  useEffect(() => {
    execute && hashid && execute({ id: hashid.split("_")[0], title: hashid.split("_")[1] });
  }, [execute, hashid]);

  useEffect(() => {
    if (value === null) return;
    setMediaMetaTags({
      title: `${value.data.product} - ${value.data.category}  - Yok Medios`,
      keywords: `${mediaSettings.keywords}, ${value.data.product}`,
      description: value.data.description,
      image: value.data.image_card,
      'image:width': 600,
      'image:height': 730
    })
  }, [value]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="media-program-page" metadata={false}> 
      {(status === "pending" || status === "idle") && <MediaProgramLoading />}
      {status === "success" && value !== null &&
        <>
          <MediaProgramHeader program={value} />
          { <MediaProgramBody program={value} />}
        </>}
    </ContentWraper>
  );
}

export default MediaProgramPage;
