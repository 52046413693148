import { FC,useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, A11y } from 'swiper';
import { 
  ContentsAPI,
  useAsync,
  StoryCard,
  StoryCardSkeleton,
  IContent
} from 'shared';
import './styles.scss';

SwiperCore.use([Autoplay, Pagination, Navigation, A11y]);

interface IProps {
  color: 'news' | 'media';
  query?: {},
  defaultData?: IContent[],
  navigation?: boolean;
  pagination?: boolean;
}

const StoriesSwiper: FC<IProps> = ({
  color,
  query = {},
  defaultData = [],
  navigation = false,
  pagination = true
}) => {
  const isRendered = useRef<boolean>(false);
  const { execute, status, value } = useAsync(ContentsAPI.getFeed, query, false);

  useEffect(() => {
    isRendered.current = true;
    return () => {
      isRendered.current = false;
    }
  }, []);

  useEffect(() => {
    if (defaultData.length === 0 && !!query === true && isRendered.current === true) {
      execute(query);
    }
  }, [query, defaultData, execute]);

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView='auto'
      grabCursor
      edgeSwipeDetection
      slideToClickedSlide={false}
      className={['stories-swiper', `${color}-colors`, ...(!!pagination ? ['--have-pagination'] : []), ...(!!navigation ? ['--have-navigation'] : [])].join(' ')}
      {...!!pagination && { pagination: { clickable: true } }}
      {...!!navigation && { navigation: { } }}
    >
      {defaultData.length === 0 && status !== 'success' && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
        <SwiperSlide
          key={index}
        >
          <StoryCardSkeleton />
        </SwiperSlide>
      ))}

      {defaultData.length === 0 && status === 'success' && Array.isArray(value) && value.map((item: IContent, index: number) => (
        <SwiperSlide 
          key={index}
        >
          <StoryCard content={item} />
        </SwiperSlide>
      ))}

      {Array.isArray(defaultData) === true && defaultData.map((item: IContent, index: number) => (
        <SwiperSlide 
          key={index}
        >
          <StoryCard content={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default StoriesSwiper;