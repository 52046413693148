import { 
  http,
  apiHeaders,
  apiBasePath,
  IProducts,
  IProductDetail
} from 'shared';

const servicePath = 'web/products';

interface IProductParams {
  id: number;
  tite: string;
}

interface IProductsParams {
  platform?: string;
  platform_id?: number[];
  section?: string;
  section_id?: number[];
  category?: string;
  category_id?: number[];
  product?: string;
  highlight?: number[];
  tag?: string[];
  includeContents?: number[];
  limit?: number;
  offset?: number;
}

export const ProductsAPI = {
  get: (requestParams: IProductParams): Promise<IProductDetail> => {
    const url = `${apiBasePath}/${servicePath}`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.product);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  getFeed: (requestParams: IProductsParams): Promise<IProducts[]> => {
    const url = `${apiBasePath}/${servicePath}`;
    // Set defaults
    if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
    if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.products);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  getHighlighted: (requestParams: IProductsParams): Promise<IProducts[]> => {
    const url = `${apiBasePath}/${servicePath}/highlight`;
    // Set defaults
    if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
    if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }  
}

export const getProduct = (requestParams: IProductParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.product)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getProducts = (requestParams: IProductsParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`;

  // Set defaults
  if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
  if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;

  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.products)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getHighlightedProducts = (requestParams: IProductsParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}/highlight`;
  // Set defaults
  if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
  if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
