import { FC } from "react";
import { Link } from "react-router-dom";
import {
  IonImg
} from "@ionic/react";
import {
  IContent
} from "shared"
import "./styles.scss";

interface IProps {
  chapter: IContent;
  showProgramTitle?: boolean;
}

const ChapterCard: FC<IProps> = ({
  chapter,
  showProgramTitle = false
}) => {
  return (
    <div className="chapter-card" itemScope itemType="http://schema.org/Episode">

      <meta itemProp='url' content={`https://yokmedios.com/ver/${chapter.id}_${chapter.url_title}`} />

      <div itemProp="publisher" itemScope itemType="http://schema.org/Organization">
        <meta itemProp="url" content="https://yokmedios.com/" />
        <meta itemProp="name" content="Yok Medios" />
        <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
          <meta itemProp='url' content='https://yokmedios.com/assets/icon/android-chrome-1024x1024.png' />
        </div>
      </div>

      <div className="cover-wrapper">
        <figure
          itemProp="image"
          itemScope itemType="http://schema.org/ImageObject"
        >
          <Link
            to={`/ver/${chapter.id || chapter.id_content}_${chapter.url_title}`}
            aria-label={`Ver ${chapter.title}`}
          >
            {chapter.photo_min &&
              <IonImg
                alt={`Portada del episodio ${chapter.title}`}
                src={chapter.photo_min}
              />}
            {chapter.miniature &&
              <IonImg
                alt={`Portada del episodio ${chapter.title}`}
                src={chapter.miniature}
              />}
          </Link>
        </figure>

        <figcaption >
          <div>
            <Link
              to={`/ver/${chapter.id || chapter.id_content}_${chapter.url_title}`}
              aria-label={`Ver ${chapter.title}`}
              className='title'
              itemProp='name'
            >
              {!!chapter.episode_number && parseInt(chapter.episode_number) !== 0 && <span className="sequence">{chapter.episode_number}. </span>}
              {chapter.title}
            </Link>
            {chapter.intro && 
              <p
                className="intro"
                itemProp="abstract"
              >{chapter.intro}</p>}
            {showProgramTitle &&
              <Link
                to={`/media/${chapter.id}_${chapter.product_alias}`}
                className='program-title'
                aria-label={`Ver todos los episodios de ${chapter.product}`}
              >
                {chapter.product}
              </Link>}
          </div>
        </figcaption>
      </div>

    </div>
  );
}

export default ChapterCard;
