import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import { 
  ContentWraper,
  SearchInput,
  getNewsCategories,
  getMediaCategories,
  setView
} from 'shared';
import SearchCategoryCard from "./SearchCategoryCard";
import "./styles.scss";

interface IProps {}

const SearchPage: FC<IProps> = () => {
  const newsCategories = useSelector(getNewsCategories);
  const mediaCategories = useSelector(getMediaCategories);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="search-page">
      <IonGrid fixed>
        <IonRow>
          <IonCol size="12">
            <h1 className='page-title'>Busqueda:</h1>
            <SearchInput />
          </IonCol>
        </IonRow>
        <IonRow>
          {newsCategories.map(category => (
            <IonCol key={`news-${category.id_category}`}>
              <SearchCategoryCard category={category} section="news" />
            </IonCol>
          ))}
          {mediaCategories.map(category => (
            <IonCol key={`media-${category.id_category}`}>
              <SearchCategoryCard category={category} section="media"/>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </ContentWraper>
  );
}

export default SearchPage;
