import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  IonImg
} from '@ionic/react';
import {
  IProducts
} from 'shared';
import './styles.scss';

interface IProps {
  product: IProducts
}

const HighlightedCard: FC<IProps> = ({
  product
}) => {
  return (
    <figure className="highlighted-card">
      <Link to={`/media/${product.id}_${product.url_name}`}>
        <IonImg
          src={product.image_highlight}
          alt={product.product}
        />
      </Link>
    </figure>
  );
}

export default HighlightedCard;