import { 
  http,
  apiHeaders,
  apiBasePath,
  ICountry
} from 'shared';

const servicePath = 'authentication/services/';

export const UserServicesAPI = {
  getCountries: (requestParams: object): Promise<ICountry[]> => {
    const url = `${apiBasePath}/${servicePath}/countries`
    const data = requestParams || {}
    return new Promise((resolve, reject) => {
      http(url, 'get', data, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.countries);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}
