import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText
} from "@ionic/react";
import { 
  ContentWraper,
  useAsync,
  PlaformTermsAPI,
  setView
} from 'shared';
import parse from 'html-react-parser';
import "./styles.scss";

interface IProps {}

const TermsConditionsPage: FC<IProps> = () => {
  const history = useHistory();
  const { execute, value, error } = useAsync(PlaformTermsAPI.getTerms, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]);

  useEffect(() => {
    execute && execute({
      section: "terms"
    });
  }, [execute]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="terms-conditions-page">
      <IonGrid fixed>
        <IonRow>
          {value === null && 
            <IonCol size="12">
              <>
                <IonSkeletonText className='loading-title' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-title separated' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
                <IonSkeletonText className='loading-text' animated />
              </>
            </IonCol>}
          {value !== null &&
            <IonCol size="12">
              {parse(value)}
            </IonCol>}
        </IonRow>
      </IonGrid>
    </ContentWraper>
  );
}

export default TermsConditionsPage;
