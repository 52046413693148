import { FC } from 'react';
import { IonSkeletonText } from '@ionic/react';
import './styles.scss';

interface IProps {}

const StoryCardSkeleton: FC<IProps> = () => {
  return (
    <IonSkeletonText animated className={'story-card-skeleton'} />
  );
}
export default StoryCardSkeleton;