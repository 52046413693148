import { 
  http,
  apiHeaders,
  apiBasePath,
  searchSettings,
  ISearchResponse
} from 'shared';

const servicePath = 'web/search';

interface IParams {
  q: string;
  limit?: number;
  offset?: number;
}

export const SearchAPI = {
  get: (requestParams: IParams): Promise<ISearchResponse>  => {
    const url = `${apiBasePath}/${servicePath}`;
    // Set defaults
    if (typeof requestParams.limit === 'undefined') requestParams.limit = searchSettings.rows;
    if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export const search = (requestParams: IParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`;

  // Set defaults
  if (typeof requestParams.limit === 'undefined') requestParams.limit = searchSettings.rows;
  if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
  
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data;
        if (request.status === false && log === true) {
          console.error(request.message);
        }
        resolve(request.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}
