import { FC, useEffect, useRef } from "react";
import { Link } from 'react-router-dom'
import {
  IonImg,
  IonBreadcrumbs,
  IonBreadcrumb
} from '@ionic/react'
import {
  ShareMenu,
  IProductDetail
} from "shared";
import "./styles.scss";

interface IProps {
  program: IProductDetail
}

const MediaProgramHeader: FC<IProps> = ({
  program
}) => {
  const { data, contents } = program;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current === null) return;
    ref.current.style.backgroundImage = `url(${data.image_banner})`
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
      ref.current.style.backgroundAttachment = 'scroll'
    }
  }, [data, contents])

  return (
    <section
      className='program-header'
      ref={ref}
    >
      <div
        className='content-container'
        itemScope
        itemType='http://schema.org/CreativeWork'
      >

        <meta itemProp='url' content={window.location.href} />
        <meta itemProp='name' content={data.product} />

        <IonBreadcrumbs className="media">
          <IonBreadcrumb>
            <Link className="navigation-breadcrumb" to="/media/explorar">
              Media
            </Link>
          </IonBreadcrumb>
          <IonBreadcrumb>
            <Link className="navigation-breadcrumb" to={`/media/${data.category_alias}/explorar`}>
              {data.category}
            </Link>
          </IonBreadcrumb>
        </IonBreadcrumbs>

        <section
          itemProp='publisher'
          itemScope
          itemType='http://schema.org/Organization'
        >
          <meta itemProp='url' content='https://yokmedios.com/' />
          <meta itemProp='name' content='Yok Medios' />
          <div itemProp='logo' itemScope itemType='http://schema.org/ImageObject'>
            <meta itemProp='url' content='https://yokmedios.com/assets/icon/android-chrome-1024x1024.png' />
          </div>
        </section>

        <section
          className='logo-container'
          itemProp='image'
          itemScope
          itemType='http://schema.org/ImageObject'
        >
          <meta itemProp='contentUrl' content={data.image_logo} />
          <IonImg
            className='img-logo'
            src={data.image_logo}
            alt={`Logo de ${data.product}`}
          />
        </section>

        <section className='classification-container'>
          <h1 className='title'>
            {data.product}
          </h1>
          <p className='intro' itemProp='abstract'>
            {data.description}
          </p>
          <p className='classification'>
            {data.producer}
          </p>
          <p className='classification'>
            {data.classification}
          </p>
          <p className='classification'>
            {data.cast}
          </p>
          <p className='classification' itemProp='datePublished'>
            {data.year}
          </p>
        </section>

        <section className='share-buttons-container'>
          <ShareMenu
            title={data.product}
            intro={data.description}
            tags={[]}
            section='Media'
          />
        </section>
      </div>
    </section>
  )
}

export default MediaProgramHeader;
