import { FC } from "react";
import {
  IonImg,
  IonButton,
  IonIcon
} from "@ionic/react"
import {
  logoInstagram,
  logoYoutube,
  logoTwitter,
  mailOutline,
  globeOutline
} from "ionicons/icons"
import { YokIcon, IEditor } from "shared";
import "./styles.scss";

interface IProps {
  editor: IEditor;
}

const EditorProfile: FC<IProps> = ({ editor }) => (
  <section className="editor-profile">
    <div className="wrapper">
      <IonImg src={editor.picture} alt={`Foto de ${editor.name}`} />
      <h1 className="editor-name">{editor.name}</h1>
      {editor?.presentation && (
        <p className="presentation">{editor.presentation}</p>
      )}
      <div className="contact-wrapper">
        {editor?.email && (
          <IonButton
            aria-label="Contactar por email"
            rel="noopener noreferrer"
            href={`mailto:${editor.email}`}
            target="_blank"
          >
            <IonIcon icon={mailOutline} />
          </IonButton>
        )}
        {editor?.web_page && (
          <IonButton
            aria-label={`Abrir página web de ${editor.name}`}
            rel="noopener noreferrer"
            href={editor.web_page}
            target="_blank"
          >
            <IonIcon icon={globeOutline} />
          </IonButton>
        )}
        {editor?.instagram_profile && (
          <IonButton
            aria-label={`Abrir perfil de Instagram de ${editor.name}`}
            rel="noopener noreferrer"
            href={editor.instagram_profile}
            target="_blank"
          >
            <IonIcon icon={logoInstagram} />
          </IonButton>
        )}
        {editor?.facebook_profile && (
          <IonButton
            aria-label={`Abrir perfil de Facebook de ${editor.name}`}
            rel="noopener noreferrer"
            href={editor.facebook_profile}
            target="_blank"
          >
            <YokIcon icon="facebook" />
          </IonButton>
        )}
        {editor?.twitter_profile && (
          <IonButton
            aria-label={`Abrir perfil de Twitter de ${editor.name}`}
            rel="noopener noreferrer"
            href={editor.twitter_profile}
            target="_blank"
          >
            <IonIcon icon={logoTwitter} />
          </IonButton>
        )}
        {editor?.youtube_channel && (
          <IonButton
            aria-label="Abrir perfil de YouTube de {{ ::vm.editor.name }}"
            rel="noopener noreferrer"
            href={editor.youtube_channel}
            target="_blank"
          >
            <IonIcon icon={logoYoutube} />
          </IonButton>
        )}
      </div>
    </div>
  </section>
);

export default EditorProfile;
