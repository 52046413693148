import { FC } from 'react';
import parse from 'html-react-parser'
import EmbedFrame from '../EmbedFrame'
import {
  IContentBlock1
} from "shared";

interface IProps {
  content: IContentBlock1
}
const Block1: FC<IProps> = ({ content }) => (
  <div className='block1'>
    <div className='text-content'>
      {content?.decodedData.text && parse(content.decodedData.text)}
    </div>
    <div className='embed-content'>
      {content?.decodedData.embed && <EmbedFrame content={content.decodedData.embed.src}  source={content.decodedData.embed.type} />}
    </div>
  </div>
)

export default Block1
