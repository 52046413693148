import { FC } from 'react';
import { Link } from 'react-router-dom';

import ChapterRelatedSwiper from './ChapterRelatedSwiper';
import MediaSwiper from './MediaSwiper';
import NewsSwiper from './NewsSwiper';
import StoriesSwiper from './StoriesSwiper';

import './styles.scss';

interface IProps {
  swiperType: 'chapter-related-cards' | 'media-cards' | 'news-cards'  | 'story-cards';
  color: 'news' | 'media';
  query?: {},
  defaultData?: any[],
  navigation?: boolean;
  pagination?: boolean;
  backgrounded?: boolean;
  titleText?: string;
  titleVariant?: 'primary' | 'default';
  titleUnderlined?: boolean;
  navigateToText?: string;
  navigateToVariant?: 'primary' | 'default';
  navigateToUnderlined?: boolean,
  navigateToUrl?: string,
}

const ContentSwiper: FC<IProps> = ({
  swiperType,
  color,
  query,
  defaultData = [],
  navigation = false,
  pagination = true,
  backgrounded,
  titleText,
  titleVariant = 'default',
  titleUnderlined,
  navigateToText,
  navigateToVariant = 'default',
  navigateToUnderlined,
  navigateToUrl = ''
}) => {
  return (
    <section className={['content-swiper', swiperType, color, ...(!!backgrounded ? ['--backgrounded'] : [])].join(' ')}>
      {!!titleText &&
        <h2 className={['swiper-title', `--${titleVariant}`, ...(!!titleUnderlined ? ['--underlined'] : [])].join(' ')}>
          {titleText}
        </h2>}

      {swiperType === 'chapter-related-cards' &&
        <ChapterRelatedSwiper 
          color={color}
          query={query}
          defaultData={defaultData}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'media-cards' &&
        <MediaSwiper 
          color={color}
          query={query}
          defaultData={defaultData}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'news-cards' &&
        <NewsSwiper 
          color={color}
          query={query}
          defaultData={defaultData}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'story-cards' &&
        <StoriesSwiper 
          color={color}
          query={query}
          defaultData={defaultData}
          navigation={navigation}
          pagination={pagination}
        />}

      {!!navigateToText &&
        <Link
          to={navigateToUrl}
          className={['swiper-link', `--${navigateToVariant}`, ...(!!navigateToUnderlined ? ['--underlined'] : [])].join(' ')}
        >
          {navigateToText}
        </Link>}
    </section>
  )
}

export default ContentSwiper;
export {
  ChapterRelatedSwiper,
  MediaSwiper,
  NewsSwiper,
  StoriesSwiper
};