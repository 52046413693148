import { FC, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  ContentWraper,
  ContentsAPI,
  useAsync,
  setMediaMetaTags,
  useQuery,
  setView
} from "shared";
import WatchContent from "./WatchContent";
import WatchLoading from "./WatchLoading";
import WatchPreview from "./WatchPreview";
import WatchRelated from "./WatchRelated";
import "./styles.scss";

interface IProps {}

const WatchPage: FC<IProps> = () => {
  const query = useQuery();
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string }>();
  const { execute, status, value, error } = useAsync(ContentsAPI.get, {}, false);
  const { execute:executeRelated, value:valueRelated } = useAsync(ContentsAPI.getRelated, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]);

  useEffect(() => {
    const preview = query.get('preview');
    execute && hashid && execute({
      id: hashid.split('_')[0],
      title: hashid.split('_')[1],
      ...(!!preview ? { preview: preview } : {})
    });
  }, [execute, hashid]);

  useEffect(() => {
    if (value === null) return;
    executeRelated({id:value.header.id});
    setMediaMetaTags({
      type: 'article',
      author: 'Yok Medios',
      title: `${value.header.title} - Yok Medios`,
      keywords: value.header.tags.map((tag) => tag.name).join(','),
      description: value.header.intro,
      image: value.header.miniature,
      'image:width': value.header.miniature_width,
      'image:height': value.header.miniature_height,
      'article:published_time': value.header.date,
      'article:section': value.header.category
    })
  }, [executeRelated, value]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="watch-page" metadata={false}>
      <section
        itemProp="publisher"
        itemScope
        itemType="http://schema.org/Organization"
      >
        <meta itemProp="url" content="https://yokmedios.com/" />
        <meta itemProp="name" content="Yok Medios" />
        <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
          <meta
            itemProp="url"
            content="https://yokmedios.com/assets/yokmedios_badge.png"
          />
        </div>
      </section>

      {(status === "pending" || status === "idle") && <WatchLoading />}

      {status === "success" && value !== null &&
        <>
          {value.header.id_status !== "1" && <WatchPreview content={value} />}
          <WatchContent content={value} />
          {valueRelated !== null && <WatchRelated related={valueRelated} />}
        </>}
    </ContentWraper>
  );
}

export default WatchPage;
