import { FC } from 'react';
import {
  IonGrid,
  IonRow
} from "@ionic/react";
import {
  TRelatedContents,
  NewsCard
} from "shared";
import './styles.scss';

interface IProps {
  related: TRelatedContents;
}

const ReadRelated: FC<IProps> = ({
  related
}) => {
  if (related === null || related === undefined) return null;
  return (
    <section className="read-related">
      <h1 className='related-title'>Tal vez te pueda interesar:</h1>
      <IonGrid>
        <IonRow>
          {related.relatedType === "nearestContents" && related.related.previous.result === true && <NewsCard content={related.related.previous.data} />}
          {related.relatedType === "nearestContents" && related.related.following.result === true && <NewsCard content={related.related.following.data} />}
        </IonRow>
      </IonGrid>
    </section>
  )
};

export default ReadRelated;
