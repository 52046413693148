import { combineReducers } from "redux";
import contentSlice from './contentSlice';
import navigationSlice from "./navigationSlice";


const rootReducer = combineReducers({
  contents: contentSlice,
  navigation: navigationSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

export { setContents } from './contentSlice';
export { 
  setCurrentTab,
  setShowReturnButton,
  setFetchingNewsCategories,
  setNewsCategories,
  setFetchingMediaCategories,
  setMediaCategories
} from './navigationSlice';
