
import { FC } from 'react';
import { 
  IonPage
} from '@ionic/react';
import { Header } from '../components';

interface IProps {
  children: any;
}
const DefaultLayout: FC<IProps> = ({ children }) => (
  <IonPage>
    <Header />
    {children}
  </IonPage>
)

export default DefaultLayout
