import { useState, useEffect, useRef } from "react";
import { IonImg } from "@ionic/react";
import { Luminous } from "luminous-lightbox";
import parse from "html-react-parser";
import "luminous-lightbox/dist/luminous-basic.min.css";
import "./styles.scss";

const SingleImage = (props) => {
  const { picture } = props;
  const [data, setData] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    if (typeof picture === "object") {
      const image = {
        alt: picture.alt || "",
        title: picture.title || "",
        main: picture.src.url || "",
        thumb: picture.src_min.url || "",
        valid: true,
      };
      if (image.main === "" && image.thumb === "") {
        setData(false);
      } else {
        if (image.main !== "" && image.thumb === "") {
          image.thumb = image.main;
        } else if (image.main === "" && image.thumb !== "") {
          image.main = image.thumb;
        }
        setData(image);
      }
    }
  }, [picture]);

  useEffect(() => {
    if (data !== false) {
      const l = new Luminous(
        imageRef.current.querySelector(".lightbox-trigger ")
      );
    }
  }, [data]);
  return (
    <div className="single-image-container" ref={imageRef}>
      {data && (
        <figure>
          <a
            href={data.main}
            className="lightbox-trigger"
            aria-label={`Ver más de ${data.alt}'`}
          >
            <IonImg
              src={data.thumb}
              alt={data.alt}
              className="lightbox-thumb"
            />
          </a>
          {data?.title && <figcaption>{parse(data.title)}</figcaption>}
        </figure>
      )}
    </div>
  );
};

export default SingleImage;
