import { FC } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonBreadcrumbs,
  IonBreadcrumb
} from "@ionic/react";
import {
  AdvertisementCardSkeleton
} from 'shared'
import './styles.scss';

interface IProps {}

const WatchLoading: FC<IProps> = () => {
  return (
    <section className="watching-loading">
      <AdvertisementCardSkeleton />
      <IonGrid fixed>
        <IonRow>
          <IonCol size="12" className="space-between">
            <IonBreadcrumbs>
              <IonBreadcrumb>
                <IonSkeletonText animated />
              </IonBreadcrumb>
              <IonBreadcrumb>
                <IonSkeletonText animated />
              </IonBreadcrumb>
              <IonBreadcrumb>
                <IonSkeletonText animated />
              </IonBreadcrumb>
            </IonBreadcrumbs>
            <IonSkeletonText animated className="share-menu-skeleton" />
          </IonCol>
          <IonCol size="12">
            <IonSkeletonText animated className="loading-title" />
          </IonCol>
          <IonCol size="12">
            <IonSkeletonText animated className="loading-banner" />
            <IonSkeletonText animated className="intro" />
          </IonCol>
          <IonCol size="12">
            <IonSkeletonText animated className="loading-content" />
            <IonSkeletonText animated className="loading-content" />
            <IonSkeletonText animated className="loading-content" />
            <IonSkeletonText animated className="loading-content" />
          </IonCol>
        </IonRow>
      </IonGrid>
      <AdvertisementCardSkeleton />
    </section>  
  )
};

export default WatchLoading;
