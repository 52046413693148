import { FC,useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, A11y } from 'swiper';
import { 
  StoryCardSkeleton
} from 'shared';
import './styles.scss';

SwiperCore.use([Autoplay, Pagination, Navigation, A11y]);

interface IProps {
  color: 'news' | 'media';
  navigation?: boolean;
  pagination?: boolean;
}

const StoriesSwiperSkeleton: FC<IProps> = ({
  color,
  navigation = false,
  pagination = true
}) => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView='auto'
      grabCursor
      edgeSwipeDetection
      slideToClickedSlide={false}
      className={['stories-swiper', `${color}-colors`, ...(!!pagination ? ['--have-pagination'] : []), ...(!!navigation ? ['--have-navigation'] : [])].join(' ')}
      {...!!pagination && { pagination: { clickable: true } }}
      {...!!navigation && { navigation: { } }}
    >
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
        <SwiperSlide
          key={index}
        >
          <StoryCardSkeleton />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default StoriesSwiperSkeleton;