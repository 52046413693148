import { 
  http,
  apiHeaders,
  apiBasePath,
  IContent,
  IContentDetail,
  TRelatedContents
} from 'shared';

const servicePath = 'web/contents';

interface IContentParams {
  id: number;
  title: string;
  preview?: number;
}

interface IfeedParams {
  platform?: string;
  platform_id?: number[];
  section?: string;
  section_id?: number[];
  category?: string;
  category_id?: number[];
  product?: string;
  product_id?: number[];
  editor_id?: number[];
  highlighted?: number[];
  tag?: string[];
  limit?: number;
  offset?: number;
}

export const ContentsAPI = {
  get: (requestParams: IContentParams): Promise<IContentDetail> => {
    const url = `${apiBasePath}/${servicePath}`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.content);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  getRelated: (requestParams: IContentParams): Promise<TRelatedContents> => {
    const url = `${apiBasePath}/${servicePath}/related`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  getFeed: (requestParams: IfeedParams = {}): Promise<IContent[]> => {
    const url = `${apiBasePath}/${servicePath}`;
    // Set defaults
    if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
    if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.contents);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export const getContent = (requestParams: IContentParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`;
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data;
        if (request.status === false && log === true) {
          console.error(request.message);
        }
        resolve(request.data.content);
      })
      .catch((error) => {
        reject(error);
      })
  });
}

export const getRelatedContents = (requestParams: IContentParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}/related`;
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data;
        if (request.status === false && log === true) {
          console.error(request.message);
        }
        resolve(request.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export const getContentFeed = (requestParams: IfeedParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`;
  // Set defaults
  if (typeof requestParams.limit === 'undefined') requestParams.limit = 12;
  if (typeof requestParams.offset === 'undefined') requestParams.offset = 0;
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data;
        if (request.status === false && log === true) {
          console.error(request.message);
        }
        resolve(request.data.contents);
      })
      .catch((error) => {
        reject(error);
      })
  })
}
