import { FC, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  ContentWraper,
  ContentsAPI,
  useAsync,
  setNewsMetaTags,
  useQuery,
  setView
} from "shared";
import ReadContent from "./ReadContent";
import ReadLoading from "./ReadLoading";
import ReadPreview from "./ReadPreview";
import ReadRelated from "./ReadRelated";
import "./styles.scss";

interface IProps {}

const ReadPage: FC<IProps> = () => {
  const query = useQuery();
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string }>();
  const { execute, status, value, error } = useAsync(ContentsAPI.get, {}, false);
  const { execute:executeRelated, value:valueRelated } = useAsync(ContentsAPI.getRelated, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]);

  useEffect(() => {
    const preview = query.get('preview');
    execute && hashid && execute({
      id: hashid.split('_')[0],
      title: hashid.split('_')[1],
      ...(!!preview ? { preview: preview } : {})
    });
  }, [execute, hashid]);

  useEffect(() => {
    if (value === null) return;
    executeRelated({id:value.header.id});
    setNewsMetaTags({
      type: 'article',
      author: value.header.editor_name,
      title: `${value.header.title} - Yok Medios`,
      keywords: value.header.tags.map((tag) => tag.name).join(','),
      description: value.header.intro,
      image: value.header.miniature,
      'image:width': value.header.miniature_width,
      'image:height': value.header.miniature_height,
      'article:published_time': value.header.date,
      'article:section': value.header.category
    })
  }, [executeRelated, value]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="read-page" metadata={false}>
      {(status === "pending" || status === "idle") && <ReadLoading />}

      {status === "success" && value !== null &&
        <>
          {value.header.id_status !== "1" && <ReadPreview content={value} />}
          <ReadContent content={value} />
          {valueRelated !== null && <ReadRelated related={valueRelated} />}
        </>}
    </ContentWraper>
  );
}

export default ReadPage;
