import { FC, useEffect, useState } from 'react'
import {
  IonFab, IonFabButton, IonFabList
} from '@ionic/react'
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { appBasePath, YokIcon, IContentTag } from 'shared'
import './styles.scss'

interface IProps {
  title?: string;
  intro?: string;
  tags?: IContentTag[];
  section?: string;
  dark?: boolean;
}

const ShareMenu: FC<IProps> = ({ title, intro, tags, section, dark }, ...props) => {
  const [url, setUrl] = useState<string | null>(null);
  const [email, setEmail] = useState<{ body: string;subject: string; } | null>(null);
  const [facebook, setFacebook] = useState<{ quote: string } | null>(null);
  const [telegram, setTelegram] = useState<{ title: string } | null>(null);
  const [twitter, setTwiter] = useState< { title: string; hashtags: string[] }| null>(null);
  const [whatsapp, setWhatsapp] = useState<{ title: string; separator: string; } | null>(null);

  const html2text = (html: string) => {
    const tag = document.createElement('div')
    tag.innerHTML = html
    return tag.innerText
  }

  useEffect(() => {
    const _section = section || 'Yok Medios'
    const _url = `${appBasePath}${window.location.pathname}`
    const _parsedIntro = html2text(intro || "");
    setUrl(_url)

    const _email = {
      body: `${title} \n ${_parsedIntro} \n Podés ver el contenido en ${_url} \n ${_section}`,
      subject: _section + ' | ' + title
    }
    setEmail(_email)

    const _facebook = {
      quote: `${section} | ${title} | ${_parsedIntro}`
    }
    setFacebook(_facebook)

    const _telegram = {
      title: `${title} \n ${_parsedIntro} \n Yok Radio`
    }
    setTelegram(_telegram)

    const _twitter: {
      title: string;
      hashtags: string[];
    } = {
      title: _section + ' | ' + title,
      hashtags: []
    }

    if (tags !== undefined) {
      tags.map((tag) => {
        _twitter.hashtags.push(tag.name.replace(/\s+/g, ''))
        return _twitter.hashtags
      })
    }
    setTwiter(_twitter)

    const _whatsapp = {
      title: `*${title}* \n _${_parsedIntro}_ \n *_Yok Radio_*`,
      separator: ' \n'
    }
    setWhatsapp(_whatsapp)
  }, [title, intro, tags, section])

  return (
    <IonFab className={['share-menu', ...(!!section ? [section.toLowerCase()] : []),...(!!dark ? ['--dark'] : [])].join(' ')} {...props}>
      <IonFabButton size="small">
        <YokIcon icon='share' />
      </IonFabButton>
      
      <IonFabList side="bottom">
        {whatsapp && url &&
          <WhatsappShareButton
            className='whatsapp'
            url={url}
            title={whatsapp.title}
            separator={whatsapp.separator}
          >
            <YokIcon icon='whatsapp' />
          </WhatsappShareButton>}
        {facebook && url &&
          <FacebookShareButton
            className='facebook'
            url={url}
            quote={facebook.quote}
          >
            <YokIcon icon='facebook' />
          </FacebookShareButton>}
        {twitter && url &&
          <TwitterShareButton
            className='twitter'
            url={url}
            title={twitter.title}
            hashtags={twitter.hashtags}
          >
            <YokIcon icon='twitter' />
          </TwitterShareButton>}
        {telegram && url &&
          <TelegramShareButton
            className='telegram'
            url={url}
            title={telegram.title}
          >
            <YokIcon icon='paper-plane-o' />
          </TelegramShareButton>}  
        {email && url &&
          <EmailShareButton
            className='email'
            subject={email.subject}
            body={email.body}
            url={url}
          >
            <YokIcon icon='mail_outline' />
          </EmailShareButton>}    
      </IonFabList>
    </IonFab>
  )
}

export default ShareMenu
