import { useState, useEffect, useRef } from 'react'
import {
  IonImg,
  IonGrid,
  IonRow
} from '@ionic/react'
import { LuminousGallery } from 'luminous-lightbox'
import 'luminous-lightbox/dist/luminous-basic.min.css'
import './styles.scss'

const Block7 = (props) => {
  const { content } = props
  const [data, setData] = useState(false)
  const galleryRef = useRef()

  useEffect(() => {
    const gallery = []
    if (Array.isArray(content.decodedData) === true) {
      content.decodedData.map((item) => {
        const image = {
          alt: item.alt || '',
          main: item.src.url || '',
          thumb: item.src_min.url || ''
        }
        if (image.main === '' && image.thumb === '') {
          return image
        } else {
          if (image.main !== '' && image.thumb === '') {
            image.thumb = image.main
          } else if (image.main === '' && image.thumb !== '') {
            image.main = image.thumb
          }
          gallery.push(image)
          return image
        }
      })
      setData(gallery)
    } else {
      setData(false)
    }
  }, [content])

  useEffect(() => {
    if (data !== false) {
      new LuminousGallery(galleryRef.current.querySelectorAll('.lightbox-trigger'))
    }
  }, [data])

  return (
    <section className='block7' ref={galleryRef}>
      <IonGrid>
        <IonRow className='ion-justify-content-around'>
          {data && data.map((item, index) => (
            <div
              key={index}
              className='item-gallery-wrapper'
            >
              <figure>
                <a
                  href={item.main}
                  className='lightbox-trigger'
                >
                  <IonImg
                    src={item.thumb}
                    className='lightbox-thumb'
                    alt={item.alt || ''}
                  />
                </a>
              </figure>
            </div>
          ))}
        </IonRow>
      </IonGrid>
    </section>
  )
}

export default Block7
