import { useState, FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonButton,
  IonImg,
  isPlatform
} from '@ionic/react'
import {
  close,
  logoInstagram,
  logoYoutube,
  logoWhatsapp,
  chevronForwardOutline
} from 'ionicons/icons'
import {
  staticResourcesPath,
  navigationOptions,
  YokIcon,
  InavigationOption,
  getNewsCategories
} from 'shared'
import './styles.scss'

interface IProps {
  option: InavigationOption;
}
/**
 * This component renders Navbar used on Layout
 * @returns React Component
 */
const Navbar = () => {
  /**
   * States
   */
  const newsCategories = useSelector(getNewsCategories);

  /**
   * Components
   */
  const SimpleOption: FC<IProps> = ({ option }) => (
    <IonItem lines='none'>
      <IonMenuToggle>
        <IonLabel>
          <Link
            to={option.url}
            aria-label={option.label}
          >
            {option.name}
          </Link>
        </IonLabel>
      </IonMenuToggle>
    </IonItem>
  );

  const NestedOption: FC<IProps> = ({ option }) => {
    const [isOpen, setIsOpen] = useState(true)
    const toogle = () => {
      setIsOpen(!isOpen)
    }
    return (
      <>
        <IonItem
          button
          onClick={toogle}
          detail={false}
          lines='none'
        >
          <IonLabel>{option.name}</IonLabel>
          <IonIcon
            className={isOpen ? 'active' : ''}
            size='48'
            icon={chevronForwardOutline}
            slot='end'
          />
        </IonItem>
        <IonList className={`subcategories ${isOpen ? 'active' : ''}`}>
          {option.name !== "News" && option.values && option.values.map((option, id) => (
            <SimpleOption option={option} key={id} />
          ))}
          {option.name === "News" && newsCategories.length > 0 && newsCategories.map((option, id) => (
            <SimpleOption option={{
              type: "simple",
              name: option.category,
              url: `/news/${option.category_alias}`,
              label: `Explora los últimos News de ${option.category}`
            }} key={id} />
          ))}
        </IonList>
      </>
    )
  }

  const TermsLinks: FC = () => (
    <>
      <IonMenuToggle>
        <Link
          className='terms-button'
          to='/terminos-y-condiciones'
          aria-label='Ver Terminos y Condiciones del sitio web y app'
        >
          Terminos y Condiciones
        </Link>
      </IonMenuToggle>
      <IonMenuToggle>
        <Link
          className='terms-button'
          to='/acuerdo-de-privacidad'
          aria-label='Ver Política de Privacidad del sitio web y app'
        >
          Acuerdo de Privacidad
        </Link>
      </IonMenuToggle>
    </>
  );

  const DownloadApp = () => (
    <section className="download-app">
      <h2 className='get-the-app-title'>Dispobinle en:</h2>
      <div className='get-the-app'>
        <figure className='app-gallery'>
          <a
            href='https://appgallery.huawei.com/#/app/C104451163'
            aria-label='Descarga Yok Medios en App Gallery'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonImg
              src={`${staticResourcesPath}/images/stores/appgallery.png`}
              alt='Descarga Yok Medios en App Gallery'
            />
          </a>
        </figure>
        <figure className='google-play'>
          <a
            href='https://play.google.com/store/apps/details?id=com.yokradio.app'
            aria-label='Descarga Yok Medios en Google Play'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonImg
              src={`${staticResourcesPath}/images/stores/playstore.png`}
              alt='Descarga Yok Medios en Google Play'
            />
          </a>
        </figure>
        <figure className='app-store'>
          <a
            href='https://apps.apple.com/cr/app/yok-radio/id1562237801'
            aria-label='Descarga Yok Medios en App Store'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonImg
              src={`${staticResourcesPath}/images/stores/appstore.png`}
              alt='Descarga Yok Medios en App Store'
            />
          </a>
        </figure>
      </div>
    </section>
  );
  
  const RelatedSites = () => {
    const options = [
      {
        icon: "yr-radio-new",
        url: "https://yokradio.com",
        label: "Visita el sitio de Yok Radio",
        name: "Yok Radio"
      },
      {
        icon: "yok-news",
        url: "https://yok.news",
        label: "Visita el sitio de Yok News",
        name: "Yok News"
      }/* ,
      {
        icon: "yok-box",
        url: "https://yokbox.com",
        label: "Visita el sitio de YokBox",
        name: "YokBox"
      } */
    ]
    return (
      <IonList className="related-sites">
        {options && options.map((option, index) => 
          (<IonItem lines='none' key={`related-link-${index}`}>
            <IonLabel>
              <a
                href={option.url}
                aria-label={option.label}
                rel="noopener noreferrer"
                target="_blank"
              >
                {option.icon && <YokIcon icon={option.icon} />} {option.name}
              </a>
            </IonLabel>
          </IonItem>))}
      </IonList>
    )
  }
  
  return (
    <IonMenu
      side='end'
      contentId='main'
      type='overlay'
      className='main-menu'
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuToggle>
              <IonButton>
                <IonIcon size='48' icon={close} />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className='categories'>
          {navigationOptions.map((option, index) => (
            option.type === 'simple' ? 
              <SimpleOption option={option} key={`nav-option-${index}`} /> :
              <NestedOption option={option} key={`nav-option-${index}`} />
          ))}
        </IonList>
        <section className='buttons-container'>
          <IonButton
            className='rounded-button'
            href='https://api.whatsapp.com/send?phone=50672852947'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoWhatsapp} />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.facebook.com/yokmedios'
            target='_blank'
            rel='noopener noreferrer'
          >
            <YokIcon icon='facebook' />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.instagram.com/yokmedios/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoInstagram} />
          </IonButton>
          <IonButton
            className='rounded-button'
            href='https://www.youtube.com/channel/UCbJTX-I78wbbkN5Z0lviYZw'
            target='_blank'
            rel='noopener noreferrer'
          >
            <IonIcon size='48' icon={logoYoutube} />
          </IonButton>
        </section>
        <TermsLinks />
        {/* {!isPlatform('cordova') && <DownloadApp />} */}
        <RelatedSites />
      </IonContent>
    </IonMenu>
  )
}

export default Navbar
