import { FC, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { 
  ContentWraper,
  EditorAPI,
  useAsync,
  setView
} from 'shared';
import EditorProfile from "./EditorProfile";
import EditorLoading from "./EditorLoading";
import EditorContents from "./EditorContents";
import "./styles.scss";

interface IProps {}

const EditorPage: FC<IProps> = () => {
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string }>();
  const { execute, status, value, error } = useAsync(EditorAPI.get, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]); 

  useEffect(() => {
    execute && hashid && execute({ id: hashid.split('_')[0], name: hashid.split('_')[1] });
  }, [execute, hashid]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className='editor-page'>
      {(status === "pending" || status === "idle") && <EditorLoading />}
      {status === "success" && value !== null &&
        <>
          <EditorProfile editor={value} />
          <EditorContents editor={value} />
        </>}
    </ContentWraper>
  );
}

export default EditorPage;
