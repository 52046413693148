import { FC, useState, useEffect, useRef } from 'react'
import {
  IonIcon,
  IonImg,
  IonButton,
  IonSkeletonText
} from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons';
import { 
  useAsync,
  AdvertisementsAPI,
  IAdvertisement,
  IAdvertisementSetEventParams
} from 'shared';
import './styles.scss';

interface IProps {
  query?: object;
  theme?: "dark" | "light";
}

const AdvertisementCard: FC<IProps> = ({
  query = {},
  theme = "light"
}) => {
  const { execute, status, value, error } = useAsync<IAdvertisement | null>(AdvertisementsAPI.get, query, false);
  const [display, setDisplay] = useState<boolean>(true);
  const [image, setImage] = useState<string>('');
  const [type, setType] = useState<"mobil" | "desktop" | "">('');
  const itemRef = useRef<HTMLDivElement | null>(null);

  const remove = () => {
    setDisplay(false);
  }

  const eventRecord = (params: IAdvertisementSetEventParams) => {
    AdvertisementsAPI.setEvent(params);
  }

  const recordWatched = () => {
    if (value === null) return false;
    eventRecord({
      event: 'watched',
      ad_id: value.id
    })
  }

  useEffect(() => {
    if (value !== null && itemRef.current !== null) {
      setImage(itemRef.current.clientWidth < 768 ? value.photo_mobil : value.photo_pc);
      setType(itemRef.current.clientWidth < 768 ? "mobil" : "desktop");
    }
  }, [value, itemRef]) 

  useEffect(() => {
    if (display === true && !!query === true) {
      execute(query);
    }
  }, []);
  
  if (display === false) return null;
  if (error !== null) return null;

  return (
    <div 
      className={`advertisement-card ${theme} ${type}`}
      ref={itemRef!}
    >
      <figure>
        {status !== 'success' &&
          <IonSkeletonText animated />}
        {status === 'success' && value !== null &&
          <a
            href={value.link}
            onClick={() => {
              eventRecord({
                event: 'clicked',
                ad_id: value.id
              })
            }}
            aria-label={`Ver anuncio de ${value.alt}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            <IonImg
              src={image}
              alt={value.alt}
              onIonImgDidLoad={recordWatched}
              onIonError={remove}
            />
          </a>}
        <figcaption>
          <p>Anuncio</p>
          <IonButton
            aria-label='Cerrar anuncio'
            onClick={remove}
          >
            <IonIcon icon={closeCircleOutline} />
          </IonButton>
        </figcaption>
      </figure>
    </div>
  )
}

export default AdvertisementCard;
