import { FC } from 'react';
import {
  IonSkeletonText
} from "@ionic/react";
import "./styles.scss";

interface IProps {
  section?: string;
  position: "even" | "odd";
}

const ContentBlockSkeleton: FC<IProps> = ({ section = "nosotros", position }) => (
  <section className={["content-block-skeleton", section, position].join(" ")} >
    <IonSkeletonText animated />
    <IonSkeletonText animated />
  </section>
);
export default ContentBlockSkeleton;
