import { 
  http,
  apiHeaders,
  apiBasePath,
  IContactType,
  IContactParams
} from 'shared';

const servicePath = 'web/contact';

export const ContactAPI = {
  getTypes: (requestParams: object): Promise<IContactType[]> => {
    const url = `${apiBasePath}/${servicePath}/types`
    const data = requestParams || {}
    return new Promise((resolve, reject) => {
      http(url, 'get', data, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.types);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  set: (requestParams: IContactParams) => {
    const url = `${apiBasePath}/${servicePath}`;
    const data = requestParams || {};
    return new Promise((resolve, reject) => {
      http(url, 'post', data, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.status);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}
