import { FC } from 'react';
import { ContentSwiper } from 'shared';

interface IProps {}

const StoriesNavigation: FC<IProps> = () => {
  return (
    <ContentSwiper
      swiperType="story-cards"
      color="media"
      backgrounded
      query={{
        limit: 12,
        section_id: [2, 7]
      }}
    />
  );
}

export default StoriesNavigation;