import { FC, useEffect } from 'react';
import { 
  liveVideoId,
  useAsync,
  StreamingAPI,
  AdvertisementCard,
  ContentWraper,
  setView
} from 'shared';
import Live from './Live';
import LoadingLive from './LoadingLive';
import NoLive from './NoLive';

interface IProps {}

const TvPage: FC<IProps> = () => {
  const { execute, status, value } = useAsync(StreamingAPI.getStreamResource, { stream_id: liveVideoId }, false);

  useEffect(() => {
    execute && execute({ stream_id: liveVideoId });
    setView();
  }, []);

  return (
    <ContentWraper className={["tv-page", ...((status === 'idle' || status === 'pending') ? ['loading-live'] : []), ...(status === 'success' ? ['live'] : []), ...(status === 'error' ? ['no-live'] : [])].join(' ')}>
      <AdvertisementCard />

      {(status === 'idle' || status === 'pending') && <LoadingLive />}
      {status === 'success' && value !==null && <Live resource={value[0]}/>}
      {status === 'error' && <NoLive />}
      
      <AdvertisementCard />
    </ContentWraper>
  );
}

export default TvPage;
