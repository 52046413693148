import { FC, useEffect, memo } from "react";
import { IonReactRouter } from '@ionic/react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonApp,
  setupIonicReact
} from '@ionic/react';
import {
  useWindowSize,
  getNewsCategories,
  getFetchingNewsCategories,
  getMediaCategories,
  getFetchingMediaCategories,
  setFetchingMediaCategories,
  setFetchingNewsCategories,
  setNewsCategories,
  setMediaCategories,
  ProductsAPI
} from 'shared';
import AppDesktop from 'desktop';
import AppMobile from 'mobile';

import './shared/assets/fonts/Poppins/style.css';
import './shared/assets/fonts/YokIcons/style.css';
import 'simplebar-react/dist/simplebar.min.css'; // Simplebar styles
import 'swiper/css'; // Swiper plugin styles
import 'swiper/css/navigation'; // Swiper plugin - pagination feature styles
import 'swiper/css/pagination'; // Swiper plugin - pagination feature styles

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';

setupIonicReact();

interface IProps {

}

const App: FC<IProps> = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const newsCategories = useSelector(getNewsCategories);
  const fetchingNewsCategories = useSelector(getFetchingNewsCategories);
  const mediaCategories = useSelector(getMediaCategories);
  const fetchingMediaCategories = useSelector(getFetchingMediaCategories);

  const fetchNewsCategories = async () => {
    dispatch(setFetchingMediaCategories("pending"));
    const request = await ProductsAPI.getFeed({ section: 'news' });
    dispatch(setNewsCategories(request || []));
    dispatch(setFetchingNewsCategories(request ? "succeeded" : "failed"));
  }

  const fetchMediaCategories = async () => {
    dispatch(setFetchingMediaCategories("pending"));
    const request = await ProductsAPI.getFeed({ section: 'media' });
    dispatch(setMediaCategories(request || []));
    dispatch(setFetchingMediaCategories(request ? "succeeded" : "failed"));
  }

  useEffect(() => {
    if (newsCategories.length === 0 && fetchingNewsCategories !== "pending") fetchNewsCategories();
    if (mediaCategories.length === 0 && fetchingMediaCategories !== "pending") fetchMediaCategories();
  }, []);

  return (
    <IonApp className={windowSize && windowSize.width && windowSize.width > 767 ? 'desktop-app' : 'mobile-app'}>
      <IonReactRouter>
        {windowSize && windowSize.width && windowSize.width > 767 ? <AppDesktop /> : <AppMobile />}
      </IonReactRouter>
    </IonApp>
  )
};

export default memo(App);
