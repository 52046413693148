import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  IonImg
} from '@ionic/react';
import {
  IProduct
} from 'shared';
import './styles.scss';

interface IProps {
  product: IProduct
}

const MediaCard: FC<IProps> = ({
  product
}) => {
  return (
    <figure className='media-card'>
      <Link to={`/media/${product.id}_${product.url_name}`}>
        <IonImg
          src={product.image_card}
          alt={`Foto: ${product.product}`}
        />
      </Link>
    </figure>
  );
}

export default MediaCard;