import loadable from '@loadable/component';

// Layout Types
import { DefaultLayout } from './layout';

import { 
  Read,
  Watch
} from 'shared';

// Route Views
const AboutUs = loadable(() => import('shared/pages/AboutUs')); 
const ContactUs = loadable(() => import('shared/pages/ContactUs')); 
const Contents = loadable(() => import('shared/pages/Contents')); 
const Editor = loadable(() => import('shared/pages/Editor'));
const ExploreMedia = loadable(() => import('shared/pages/ExploreMedia'));
const Home = loadable(() => import('shared/pages/Home'));
const MediaByCategory = loadable(() => import('shared/pages/MediaByCategory'));
const MediaProgram = loadable(() => import('shared/pages/MediaProgram'));
const News = loadable(() => import('shared/pages/News'));
const NotFound = loadable(() => import('shared/pages/NotFound'));
const PrivacyAgreement = loadable(() => import('shared/pages/PrivacyAgreement'));
const Search = loadable(() => import('shared/pages/Search'));
const SearchResults = loadable(() => import('shared/pages/SearchResults'));
const TermsConditions = loadable(() => import('shared/pages/TermsConditions'));
const TV = loadable(() => import('shared/pages/TV'));

export enum ETabs {
  home = 'home',
  news = 'news',
  media = 'media',
  tv = 'tv',
  search = 'search'
}

interface IRoutes {
  path: string,
  exact: boolean,
  layout: any,
  component: any,
  tab: ETabs
}
export const routes : IRoutes[] = [
  {
    path: '/nosotros',
    exact: true,
    layout: DefaultLayout,
    component: AboutUs,
    tab: ETabs.home
  },
  {
    path: '/contacto',
    exact: true,
    layout: DefaultLayout,
    component: ContactUs,
    tab: ETabs.home
  },
  {
    path: '/contenido/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    exact: true,
    layout: DefaultLayout,
    component: Contents,
    tab: ETabs.home
  },
  {
    path: '/editor/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    exact: true,
    layout: DefaultLayout,
    component: Editor,
    tab: ETabs.news
  },
  {
    path: '/media/explorar',
    exact: true,
    layout: DefaultLayout,
    component: ExploreMedia,
    tab: ETabs.media
  },
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Home,
    tab: ETabs.home
  },
  {
    path: '/media/:category([a-zA-Z0-9-]{1,300})/explorar',
    exact: true,
    layout: DefaultLayout,
    component: MediaByCategory,
    tab: ETabs.media
  },
  {
    path: '/media/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    exact: true,
    layout: DefaultLayout,
    component: MediaProgram,
    tab: ETabs.media
  },
  {
    path: '/news/explorar/:tag([a-zA-Z0-9-]{1,300})?',
    exact: true,
    layout: DefaultLayout,
    component: News,
    tab: ETabs.news
  },
  {
    path: '/news/:category/:tag([a-zA-Z0-9-]{1,300})?',
    exact: true,
    layout: DefaultLayout,
    component: News,
    tab: ETabs.news
  },
  {
    path: '/404',
    exact: true,
    layout: DefaultLayout,
    component: NotFound,
    tab: ETabs.home
  },
  {
    path: '/acuerdo-de-privacidad',
    exact: true,
    layout: DefaultLayout,
    component: PrivacyAgreement,
    tab: ETabs.home
  },
  {
    path: '/leer/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    exact: true,
    layout: DefaultLayout,
    component: Read,
    tab: ETabs.news
  },
  {
    path: '/busqueda',
    layout: DefaultLayout,
    exact: true,
    component: Search,
    tab: ETabs.search
  },
  {
    path: '/resultados/:query?',
    layout: DefaultLayout,
    exact: true,
    component: SearchResults,
    tab: ETabs.search
  },
  {
    path: '/terminos-y-condiciones',
    exact: true,
    layout: DefaultLayout,
    component: TermsConditions,
    tab: ETabs.home
  },
  {
    path: '/tv',
    exact: true,
    layout: DefaultLayout,
    component: TV,
    tab: ETabs.tv
  },
  {
    path: '/ver/:hashid([0-9]{1,10}_[a-zA-Z0-9-]{1,300})',
    layout: DefaultLayout,
    exact: true,
    component: Watch,
    tab: ETabs.media
  }
];

export const navOptions = [];
