import { FC } from 'react';
import {
  IonSkeletonText
} from '@ionic/react';
import './styles.scss';

interface IProps {}

const HighlightedCardSkeleton: FC<IProps> = () => {
  return (
    <figure className="highlighted-card-skeleton">
      <IonSkeletonText animated />
    </figure>
  );
}

export default HighlightedCardSkeleton;