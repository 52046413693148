export const appId = 1

export const appVersion = 2

export const appBasePath = process.env.NODE_ENV === 'production' ? 'https://yokmedios.com' : 'https://yokmedios.com'

export const staticResourcesPath = process.env.NODE_ENV === 'production' ? 'https://yokmedios.com/assets' : 'https://yokmedios.com/assets'

export const gaTagId = {
  web: process.env.NODE_ENV === 'production' ? 'G-EPWWBPPS9N' : 'G-EPWWBPPS9N',
  android: process.env.NODE_ENV === 'production' ? 'G-EPWWBPPS9N' : 'G-EPWWBPPS9N',
  ios: process.env.NODE_ENV === 'production' ? 'G-EPWWBPPS9N' : 'G-EPWWBPPS9N'
}

export const fbAppId = process.env.NODE_ENV === 'production' ? '678370012566683' : '678370012566683'

export const fbPixelId = process.env.NODE_ENV === 'production' ? '568388307490038' : '568388307490038'
