import { FC } from 'react';
import {
  IonSkeletonText
} from '@ionic/react';
import './styles.scss';

interface IProps {}

const MediaCardSkeleton: FC<IProps> = () => (
  <figure className='media-card-skeleton'>
    <IonSkeletonText animated />
  </figure>
);

export default MediaCardSkeleton;