import { 
  http,
  apiHeaders,
  apiBasePath,
  IStreamResource
} from 'shared';

const servicePath = 'web/streaming';

export const StreamingAPI = {
  getStreamResource: (requestParams: object): Promise<IStreamResource[]> => {
    const url = `${apiBasePath}/${servicePath}`
    const data = requestParams || {}
    return new Promise((resolve, reject) => {
      http(url, 'get', data, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.resources);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  getStreamStats: (url: string) => {
    const data = {}
    return new Promise((resolve, reject) => {
      http(url, 'get', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export const getStreamResource = (requestParams: object, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`
  const data = requestParams || {}
  return new Promise((resolve, reject) => {
    http(url, 'get', data, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.resources)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getStreamStats = (url: string) => {
  const data = {}
  return new Promise((resolve, reject) => {
    http(url, 'get', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
