import { FC } from 'react';
import { ContentSwiper } from 'shared';

interface IProps {}

const NewsNavigation: FC<IProps> = () => {
  return (
    <ContentSwiper
      swiperType="news-cards"
      color="news"
      titleText='News'
      titleUnderlined
      navigateToText='ver más'
      navigateToUnderlined
      navigateToUrl='/news/explorar'
      backgrounded
      query={{
        limit: 12,
        section: 'news'
      }}
    />
  );
}

export default NewsNavigation;