import { 
  http,
  apiHeaders,
  apiBasePath,
  IEditor
} from 'shared';

const servicePath = 'web/editors';

interface IEditorParams {
  id: number;
  name: string;
}

export const EditorAPI = {
  get: (requestParams: IEditorParams): Promise<IEditor> => {
    const url = `${apiBasePath}/${servicePath}`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then(response => {
          const request = response.data;
          resolve(request.data);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}

export const getEditor = (requestParams: IEditorParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`;
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then(response => {
        const request = response.data;
        if (request.status === false && log === true) {
          console.error(request.message);
        }
        resolve(request.data);
      })
      .catch((error) => {
        reject(error);
      })
  })
}
