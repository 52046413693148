import { FC, memo } from 'react';
import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonLabel,
  IonTabButton,
} from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';
import { YokIcon } from 'shared';
import { routes, ETabs } from './routes';
import { Navbar } from 'mobile/components';
import './styles.scss';

interface IProps {}

const AppMobile: FC<IProps> = () => (
  <>
    <Navbar />
    <IonTabs>
      
      <IonRouterOutlet>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
          >
            <route.layout>
              <route.component/>
            </route.layout>
          </Route>
        ))}
        <Route exact path="/">
          <Redirect
            to={{
              pathname: "/home",
            }}
          />
        </Route>
        <Redirect to='/404' />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab={ETabs.home} href="/home">
          <YokIcon icon='house-door-fill'/>
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab={ETabs.news} href="/news/explorar">
          <YokIcon icon='newspaper' />
          <IonLabel>News</IonLabel>
        </IonTabButton>
        <IonTabButton tab={ETabs.media} href="/media/explorar">
          <YokIcon icon='camera-video-fill' />
          <IonLabel>Media</IonLabel>
        </IonTabButton>
        <IonTabButton tab={ETabs.tv} href="/tv">
          <YokIcon icon='live' />
          <IonLabel>TV</IonLabel>
        </IonTabButton>
        <IonTabButton tab={ETabs.search} href="/busqueda">
          <YokIcon icon='search' />
          <IonLabel>Buscar</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  </>
);

export default memo(AppMobile);
