import { FC } from 'react';
import {
  IonPage
} from '@ionic/react';
import { Header } from 'mobile/components/core';

interface IProps {
  children: any;
}
const DefaultLayout: FC<IProps> = ({ children } ) => ( 
  <IonPage id="main">
    <Header />
    {children}
  </IonPage>
);

export default DefaultLayout;
