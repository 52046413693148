import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import { 
  ContentWraper,
  useAsync,
  PlaformTermsAPI,
  setView
} from 'shared';
import ContentBlock from "./ContentBlock";
import ContentBlockSkeleton from "./ContentBlockSkeleton";
import "./styles.scss";

interface IProps {}

const AboutUsPage: FC<IProps> = () => {
  const history = useHistory();
  const { execute, value, error } = useAsync(PlaformTermsAPI.getAbout, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]);

  useEffect(() => {
    execute && execute({});
  }, [execute]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className='about-us-page'>
      <IonGrid fixed>
        <IonRow>
          {value === null && [undefined,"media","news","radio","podcast"].map((section, index) => (
            <IonCol size="12" key={section}>
              <ContentBlockSkeleton section={section} position={index % 2 === 0 ? "even": "odd"} />
            </IonCol>
          ))}
          
          {value !== null && value.map((content, index) => (
            <IonCol size="12" key={`about-${content.id}`}>
              <ContentBlock content={content} position={index % 2 === 0 ? "even": "odd"} />
            </IonCol>))}
        </IonRow>
      </IonGrid>
    </ContentWraper>
    
  );
}

export default AboutUsPage;
