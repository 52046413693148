import { useState, useEffect, useRef } from 'react';
import { IonImg } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, A11y } from 'swiper';
import { LuminousGallery } from 'luminous-lightbox';
import 'luminous-lightbox/dist/luminous-basic.min.css';
import './styles.scss';

SwiperCore.use([Autoplay, Pagination, A11y])

const Block8 = (props) => {
  const { content } = props
  const [data, setData] = useState([])
  const galleryRef = useRef()

  useEffect(() => {
    const gallery = [];
    if (Array.isArray(content.decodedData) === true) {
      content.decodedData.map((item) => {
        const image = {
          alt: item.alt || '',
          main: item.src.url || '',
          thumb: item.src_min.url || ''
        }
        if (image.main === '' && image.thumb === '') {
          return image
        } else {
          if (image.main !== '' && image.thumb === '') {
            image.thumb = image.main
          } else if (image.main === '' && image.thumb !== '') {
            image.main = image.thumb
          }
          gallery.push(image)
          return image
        }
      })
      setData(gallery)
    } else {
      setData(false)
    }
  }, [content])

  useEffect(() => {
    if (data !== false) {
      new LuminousGallery(galleryRef.current.querySelectorAll('.lightbox-trigger'))
    }
  }, [data])

  return (
    <section className='block8' ref={galleryRef}>
      {data.length > 0 &&
        <Swiper
          spaceBetween={0}
          slidesPerView='auto'
          pagination={ { clickable: true } }
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <figure>
                <a
                  href={item.main}
                  className='lightbox-trigger'
                >
                  <IonImg
                    src={item.thumb}
                    className='lightbox-thumb'
                    alt={item.alt || ''}
                  />
                </a>
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>}
    </section>
  )
}

export default Block8
