import { FC } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText
} from '@ionic/react';
import './styles.scss';

interface IProps {}

const LoadingLive: FC<IProps> = () => (
  <IonGrid fixed>
    <IonRow>
      <IonCol size="12">
        <h1 className="page-title --media"><IonSkeletonText animated /></h1>
      </IonCol>
      <IonCol size="12">
        <IonSkeletonText className='video' animated />
      </IonCol>
    </IonRow>
  </IonGrid>
)

export default LoadingLive
