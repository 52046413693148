import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, A11y } from 'swiper';
import {
  MediaCardSkeleton
} from 'shared';
import './styles.scss';

SwiperCore.use([Autoplay, Pagination, Navigation, A11y]);

interface IProps {
  color: 'news' | 'media';
  navigation?: boolean;
  pagination?: boolean;
}

const MediaSwiperSkeleton: FC<IProps> = ({
  color,
  navigation = false,
  pagination = true
}) => (
  <Swiper
    spaceBetween={0}
    slidesPerView='auto'
    grabCursor
    edgeSwipeDetection
    slideToClickedSlide={false}
    className={['media-swiper-skeleton', `${color}-colors`, ...(!!pagination ? ['--have-pagination'] : []), ...(!!navigation ? ['--have-navigation'] : [])].join(' ')}
    {...!!pagination && { pagination: { clickable: true } }}
    {...!!navigation && { navigation: { } }}
  >
    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
      <SwiperSlide
        key={index}
      >
        <MediaCardSkeleton />
      </SwiperSlide>
    ))}
  </Swiper>
)

export default MediaSwiperSkeleton;