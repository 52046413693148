import { FC } from 'react';

interface IProps {
  color: 'news' | 'media';
  query?: {},
  defaultData?: any,
  navigation?: boolean;
  pagination?: boolean;
}

const ChapterRelatedSwiper: FC<IProps> = ({
  color,
  query,
  defaultData,
  navigation = false,
  pagination = true,
}) => {
  return (
    <h1>ChapterRelatedSwiper swiper</h1>
  )
}

export default ChapterRelatedSwiper;