import { FC } from 'react';
import {
  IonImg
} from "@ionic/react";
import { 
  IAboutOutPut,
  EmbedFrame
} from 'shared';
import parse from 'html-react-parser';
import "./styles.scss";

interface IProps {
  content: IAboutOutPut;
  position: "even" | "odd";
}

const ContentBlock: FC<IProps> = ({ content, position }) => (
  <section className={["content-block", content.section, position].join(" ")} >
    {content.description && 
      <div className="text-container">
        {parse(content.description)}
      </div>}

    {content.photo && <IonImg src={content.photo} alt={`Logo ${content.section}`} />}

    {content.video && <EmbedFrame content={content.video} source="youtube" />}
  </section>
);
export default ContentBlock;
