import { FC } from 'react';
import parse from 'html-react-parser';
import {
  IContentBlock4
} from "shared";

interface IProps {
  content: IContentBlock4
}
const Block4: FC<IProps> = ({ content }) => (
  <div className='block4'>
    <div className='text-content'>
      {content?.decodedData.text && parse(content.decodedData.text)}
    </div>
  </div>
)

export default Block4
