import { FC } from "react";
import { Link } from "react-router-dom";
import { IReelImage } from "shared/models";
import MediaReelDesktop from "shared/assets/img/home/media_reel/600w.jpg";
import MediaReelMobile from "shared/assets/img/home/media_reel/300w.jpg";
import NewsReelDesktop from "shared/assets/img/home/news_reel/600w.jpg";
import NewsReelMobile from "shared/assets/img/home/news_reel/300w.jpg";
import "./styles.scss";

interface IProps {}

const images: IReelImage[] = [
  {
    name: "Media",
    url: "/media/explorar",
    mobile_image: MediaReelMobile,
    mobile_image_width: "300",
    mobile_image_height: "300",
    desktop_image: MediaReelDesktop,
    desktop_image_width: "600",
    desktop_image_height: "300",
    desktop_query: "1280",
    icon_class: "icon-video",
    priority: 1
  },
  {
    name: "News",
    url: "/news/explorar",
    mobile_image: NewsReelMobile,
    mobile_image_width: "300",
    mobile_image_height: "300",
    desktop_image: NewsReelDesktop,
    desktop_image_width: "600",
    desktop_image_height: "300",
    desktop_query: "1280",
    icon_class: "icon-globe",
    priority: 2
  },
];

const NavigationReel: FC<IProps> = () => (
  <section className="navigation-reel">
    {images.map((image, key) => (
      <figure key={`reel-image-${key}`}>
        <picture>
          <source srcSet={image.desktop_image} media={`(min-width: ${image.desktop_query}px)`} />
          <img src={image.mobile_image} alt={`Reel de ${image.name}`} />
        </picture>
        <Link to={image.url}>
          {image.name} {image.icon_class && image.icon_class !== "" && <i className={image.icon_class} />}
        </Link>
      </figure>
    ))}
  </section>
);

export default NavigationReel;
