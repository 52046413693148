import { FC } from 'react';
import { IonImg } from '@ionic/react';
import { Link } from 'react-router-dom';
import { 
  ContentSwiper,
  navigationOptions
} from 'shared';
import NotFoundImg from 'shared/assets/img/notFound/notFound.png';
import './styles.scss';

interface IProps {
  editorName: string;
}

const EditorNoContents: FC<IProps> = ({
  editorName
}) => {
  return (
    <div className="editor-no-content">
      <section className='notification'>
        <h1>
          Parece que eso es todo por ahora
          <span role='img' aria-label='emoji sonrisa de pena'> &#128556;</span>
        </h1>
        <IonImg
          src={NotFoundImg}
          alt=''
        />
        <p>Todo indica que {editorName ? editorName : 'este editor'} aún no hecho su primer artículo, puedes volver luego para ver todo el asombroso contenido que está preparando para vos. </p>
      </section>

      <section className='navigation'>
        <h2>
          Puedes hecharle un ojo a alguno de estos enlaces
          <span role='img' aria-label='emoji pensativo'> &#129300;</span>
        </h2>
        <div className='links'>
          {navigationOptions.map((option, index) => (
            <Link
              key={index}
              to={option.url}
              aria-label={option.label}
            >
              {option.name}
            </Link>
          ))}
        </div>
      </section>

      <section className="read-something">
        <h2
          className='read-news'
        >
          Tambien puedes leer alguna de estas
          <span role='img' aria-label='emoji anteojo de un ojo'> &#129488;</span>
        </h2>
        <ContentSwiper
          swiperType="news-cards"
          color="news"
          backgrounded={false}
          navigateToText='ver más'
          navigateToUnderlined
          navigateToUrl='/news/explorar'
          query={{
            limit: 12,
            section: 'news'
          }}
        />
      </section>
    </div>
  );
}

export default EditorNoContents;
