import { FC, useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption
} from '@ionic/react'
import {
  IProductDetail,
  IContent,
  ISerialContents,
  ChapterCard
} from "shared";
import "./styles.scss";

interface IProps {
  program: IProductDetail
}

const MediaProgramBody: FC<IProps> = ({
  program
}) => {
  const [activeSeason, setActiveSeason] = useState<IContent[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<number>(0);
  const [seasons, setSeasons] = useState<ISerialContents[]>();

  useEffect(() => {
    if (program === null || program === undefined) return;
    const index = program.contents.length - 1
      setSeasons(program.contents)
      setSelectedSeason(index)
      setActiveSeason(program.contents[index].content.reverse());
  }, [program])

  useEffect(() => {
    if (seasons === undefined) return;
    if (seasons.length > 0) {
      setActiveSeason(seasons[selectedSeason].content.reverse());
    }
  }, [seasons, selectedSeason])

  if (program === null) return null;

  return (
    <section className='program-body'>
      <div className='season-selector'>
        <IonSelect interface='popover' value={selectedSeason} placeholder='Seleccionar Temporada' onIonChange={e => setSelectedSeason(e.detail.value)}>
          {seasons && seasons.map((season, index) => (
            <IonSelectOption key={season.id_serial} value={index}>{season.serial}</IonSelectOption>
          ))}
        </IonSelect>
      </div>
      <IonGrid>
        <IonRow>
          {activeSeason.map((chapter, index) => (
            <IonCol size="12" key={`${index}-${chapter.id}`}>
              <ChapterCard chapter={chapter} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </section>
  )
}

export default MediaProgramBody;
