import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IonImg } from '@ionic/react';
import { IContent } from 'shared';
import './styles.scss';

interface IProps {
  content: IContent,
  showCategory?: boolean
}

const NewsCard: FC<IProps> = ({
  content,
  showCategory = true
}) => (
  <figure className={['news-card', ...(showCategory ? ['have-category'] : [])].join(' ')}>
    <Link
      to={`/leer/${content.id}_${content.url_title}`}
      aria-label={`Ver ${content.title}`}
      itemProp='headline'
    >
      <IonImg
        src={content.banner}
        alt={`Foto: ${content.title}`}
      />
    </Link>
    <figcaption>
      <Link
        to={`/leer/${content.id}_${content.url_title}`}
        aria-label={`Ver ${content.title}`}
        itemProp='headline'
        className="news-title"
      >
        {content.title}
      </Link>
      {showCategory && 
        <Link
          to={`/news/${content.category_alias}`}
          aria-label={`Ver todos lo news de ${content.category}`}
          itemProp='headline'
          className="news-category"
        >
          {content.category}
        </Link>}
    </figcaption>
  </figure>
);

export default NewsCard;