import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  IonSkeletonText
} from '@ionic/react';

import ChapterRelatedSwiperSkeleton from './ChapterRelatedSwiperSkeleton';
import MediaSwiperSkeleton from './MediaSwiperSkeleton';
import NewsSwiperSkeleton from './NewsSwiperSkeleton';
import StoriesSwiperSkeleton from './StoriesSwiperSkeleton';

import './styles.scss';

interface IProps {
  swiperType: 'chapter-related-cards' | 'media-cards' | 'news-cards'  | 'story-cards';
  color: 'news' | 'media';
  navigation?: boolean;
  pagination?: boolean;
  backgrounded?: boolean;
  titleText?: boolean;
  titleVariant?: 'primary' | 'default';
  titleUnderlined?: boolean;
  navigateToText?: boolean;
  navigateToVariant?: 'primary' | 'default';
  navigateToUnderlined?: boolean,
  navigateToUrl?: string,
}

const ContentSwiperSkeleton: FC<IProps> = ({
  swiperType,
  color,
  navigation = false,
  pagination = true,
  backgrounded,
  titleText,
  titleVariant = 'default',
  titleUnderlined,
  navigateToText = true,
  navigateToVariant = 'default',
  navigateToUnderlined
}) => {
  return (
    <section className={['content-swiper-skeleton', swiperType, color, ...(!!backgrounded ? ['--backgrounded'] : [])].join(' ')}>
      {titleText &&
        <h2 className={['swiper-title', `--${titleVariant}`, ...(!!titleUnderlined ? ['--underlined'] : [])].join(' ')}>
          <IonSkeletonText />
        </h2>}

      {swiperType === 'chapter-related-cards' &&
        <ChapterRelatedSwiperSkeleton 
          color={color}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'media-cards' &&
        <MediaSwiperSkeleton 
          color={color}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'news-cards' &&
        <NewsSwiperSkeleton 
          color={color}
          navigation={navigation}
          pagination={pagination}
        />}

      {swiperType === 'story-cards' &&
        <StoriesSwiperSkeleton 
          color={color}
          navigation={navigation}
          pagination={pagination}
        />}

      {navigateToText &&
        <p
          className={['swiper-link', `--${navigateToVariant}`, ...(!!navigateToUnderlined ? ['--underlined'] : [])].join(' ')}
        >
          <IonSkeletonText />
        </p>}
    </section>
  )
}

export default ContentSwiperSkeleton;
export {
  ChapterRelatedSwiperSkeleton,
  MediaSwiperSkeleton,
  NewsSwiperSkeleton,
  StoriesSwiperSkeleton
};