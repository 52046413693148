import {
  isPlatform
} from '@ionic/react'
// @ts-ignore
import { gtag, install } from 'ga-gtag';
import { gaTagId } from '../config/app'

export const initializeAnalytics = () => {
  let platform: 'web' | 'ios' | 'android' = 'web';
  if (isPlatform('ios') === true) platform = 'ios';
  if (isPlatform('android') === true) platform = 'android';
  install('create', gaTagId[platform], 'auto');
}

export const setView = (path: string = "") => {
  gtag('send', 'pageview', path || window.location.pathname);
}

interface IEvent {
  category: string;
  action: string;
  label: string;
  value: string;
}
export const setEvent = ({ category, action, label, value }: IEvent) => {
  if (typeof category === 'undefined' || typeof action === 'undefined') {
    return false
  }
  const eventArgs: {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: string;
  } = {
    eventCategory: category,
    eventAction: action
  }
  if (typeof label !== 'undefined') {
    eventArgs.eventLabel = label
  }
  if (typeof value !== 'undefined') {
    eventArgs.eventValue = value
  }
  gtag('send', 'event', eventArgs)
}
