import { FC } from 'react';
import {
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import {
  TRelatedContents,
  ChapterCard
} from "shared";
import './styles.scss';

interface IProps {
  related: TRelatedContents;
}

const WatchRelated: FC<IProps> = ({
  related
}) => {
  if (related === null || related === undefined) return null;
  return (
    <section className="watch-related">
      <h1 className='related-title'>Tal vez te pueda interesar:</h1>
      <IonGrid fixed>
        <IonRow>
          {related.relatedType === "nextChapter" && related.related.map(related => (
            <IonCol size={"12"}>
              <ChapterCard chapter={related} key={`nextchapter-${related.id}`} />
            </IonCol>
          ))}
          {related.relatedType === "cardGallery" && related.related.map(related => (
            <IonCol size={"12"}>
              <ChapterCard chapter={related} key={`cardGallery-${related.id}`} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </section>
  )
};

export default WatchRelated;
