import { FC } from 'react';
import { IonSkeletonText } from '@ionic/react';
import './styles.scss';

interface IProps {
  showCategory?: boolean;
}

const NewsCardSkeleton: FC<IProps> = ({
  showCategory = true
}) => {
  return (
    <figure className={['news-card-skeleton', ...(showCategory ? ['have-category'] : [])].join(' ')}>
      <IonSkeletonText animated />
      <figcaption>
        <IonSkeletonText animated />
        <IonSkeletonText animated />
        <IonSkeletonText animated />
        {showCategory &&
          <div className="category-title">
            <IonSkeletonText animated />
          </div>}
      </figcaption>
    </figure>
  );
}

export default NewsCardSkeleton;