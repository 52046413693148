import { FC } from "react";
import {
  IonBreadcrumbs,
  IonBreadcrumb,
  IonSkeletonText
} from "@ionic/react";
import "./styles.scss";

interface IProps {}

const MediaProgramLoading: FC<IProps> = () => {
  return (
    <section className='program-loading'>
      <div className='content-container' >

        <IonBreadcrumbs className="media">
          <IonBreadcrumb>
            <IonSkeletonText animated />
          </IonBreadcrumb>
          <IonBreadcrumb>
            <IonSkeletonText animated />
          </IonBreadcrumb>
        </IonBreadcrumbs>

        <section className='logo-container'>
          <IonSkeletonText animated className="img-logo" />
        </section>

        <section className='classification-container'>
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
          <IonSkeletonText animated />
        </section>

        <section className='share-buttons-container'>
          <IonSkeletonText animated />
        </section>
      </div>
    </section>
  )
}

export default MediaProgramLoading;
