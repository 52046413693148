import { FC } from "react";
import {
  IonSkeletonText
} from "@ionic/react";
import "./styles.scss";

interface IProps {}

const EditorLoading: FC<IProps> = () => (
  <section className="editor-loading">
    <div className="wrapper">
      <IonSkeletonText animated className="editor-photo-skeleton" />
      <IonSkeletonText animated className="editor-name-skeleton" />
      <IonSkeletonText animated className="presentation-skeleton" />
      <IonSkeletonText animated className="presentation-skeleton" />
      <div className="contact-wrapper">
        <IonSkeletonText animated className="button-skeleton" /> 
        <IonSkeletonText animated className="button-skeleton" />
        <IonSkeletonText animated className="button-skeleton" />
        <IonSkeletonText animated className="button-skeleton" />
        <IonSkeletonText animated className="button-skeleton" />
        <IonSkeletonText animated className="button-skeleton" />
      </div>
    </div>
  </section>
);

export default EditorLoading;
