import { 
  http,
  apiHeaders,
  apiBasePath,
  IAdvertisement,
  IAdvertisementLocations,
  IAdvertisementSetEventParams
} from 'shared';


const servicePath = 'web/advertisements';

export const AdvertisementsAPI = {
  get: (requestParams: IAdvertisementLocations) : Promise<IAdvertisement> => {
    const url = `${apiBasePath}/${servicePath}`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.data.advertisements);
        })
        .catch((error) => {
          reject(error)
        })
    });
  },
  setEvent: (requestParams : IAdvertisementSetEventParams) => {
    const url = `${apiBasePath}/${servicePath}/tracking`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then((response) => {
          const request = response.data;
          resolve(request.status);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export const getAdvertisement = (requestParams: IAdvertisementLocations, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}`
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.advertisements)
      })
      .catch((error) => {
        reject(error)
      })
  })
}


export const setAdvertisementEvent = (requestParams : IAdvertisementSetEventParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}/tracking`

  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then((response) => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.status)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
