import { 
  http,
  apiHeaders,
  apiBasePath,
  ITermsParams,
  IAboutParams,
  IAboutOutPut
} from 'shared';

const servicePath = 'web/information';



export const PlaformTermsAPI = {
  getTerms: (requestParams: ITermsParams): Promise<string> => {
    const url = `${apiBasePath}/${servicePath}/platform_terms`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then(response => {
          const request = response.data;
          resolve(request.data.resource);
        })
        .catch((error) => {
          reject(error)
        })
    });
  },
  getAbout: (requestParams: IAboutParams = {}): Promise<IAboutOutPut[]> => {
    const url = `${apiBasePath}/${servicePath}/yok_platform`;
    return new Promise((resolve, reject) => {
      http(url, 'get', requestParams, apiHeaders)
        .then(response => {
          const request = response.data;
          resolve(request.data.resource);
        })
        .catch((error) => {
          reject(error)
        })
    });
  }
}

export const getPlatformTerms = (requestParams: ITermsParams, log: boolean = false) => {
  const url = `${apiBasePath}/${servicePath}/platform_terms`
  return new Promise((resolve, reject) => {
    http(url, 'get', requestParams, apiHeaders)
      .then(response => {
        const request = response.data
        if (request.status === false && log === true) {
          console.error(request.message)
        }
        resolve(request.data.resource)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
