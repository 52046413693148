import { FC, useEffect } from 'react';
import { IonImg } from '@ionic/react';
import { Link } from 'react-router-dom';
import { 
  ContentSwiper,
  navigationOptions,
  ContentWraper,
  setView
} from 'shared';
import NotFoundImg from 'shared/assets/img/notFound/notFound.png';
import './styles.scss';

interface IProps {}

const NotFoundPage: FC<IProps> = () => {
  
  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="not-found-page">
      <section className='notification'>
        <h1>
          ¡Ups! Algo ha ido mal
          <span role='img' aria-label='emoji sorprendido'> &#128551;</span>
        </h1>
        <IonImg
          src={NotFoundImg}
          alt=''
        />
        <p>
          Parece que lo que buscas se ha movido de lugar o se perdió
          <span role='img' aria-label='emoji sonrisa de pena'> &#128556;</span>
        </p>
      </section>

      <section className='navigation'>
        <h2>
          Puedes hecharle un ojo a alguno de estos enlaces
          <span role='img' aria-label='emoji pensativo'> &#129300;</span>
        </h2>
        <div className='links'>
          {navigationOptions.map((option, index) => (
            <Link
              key={index}
              to={option.url}
              aria-label={option.label}
            >
              {option.name}
            </Link>
          ))}
        </div>
      </section>

      <section className="read-something">
        <h2
          className='read-news'
        >
          Tambien puedes leer alguna de estas
          <span role='img' aria-label='emoji anteojo de un ojo'> &#129488;</span>
        </h2>
        <ContentSwiper
          swiperType="news-cards"
          color="news"
          backgrounded={false}
          navigateToText='ver más'
          navigateToUnderlined
          navigateToUrl='/news/explorar'
          query={{
            limit: 12,
            section: 'news'
          }}
        />
      </section>
    </ContentWraper>
  )
};

export default NotFoundPage;
