import { FC, useEffect } from 'react';
import { ContentWraper } from 'shared';
import HighlightedNavigation from './HighlightedNavigation';
import NavigationReel from './NavigationReel';
import NewsNavigation from './NewsNavigation';
import StoriesNavigation from './StoriesNavigation';
import { 
  AdvertisementCard,
  setView
} from 'shared';
import './styles.scss';

interface IProps {
  route: any;
}

const HomePage: FC<IProps> = () => {

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className='home-page'>
      <StoriesNavigation />
      <AdvertisementCard />
      <NavigationReel />
      <NewsNavigation />
      <HighlightedNavigation />
      <AdvertisementCard />
    </ContentWraper>
  );
}

export default HomePage;
