import { FC, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonSkeletonText
} from "@ionic/react";
import {
  ContentWraper,
  NewsCard,
  NewsCardSkeleton,
  AdvertisementCard,
  IContent,
  ContentsAPI,
  newsSettings,
  setNewsMetaTags,
  setMetaTags,
  setView
} from "shared";
import "./styles.scss";

interface IProps {}

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

const genericTitle = 'Explorar News';

const NewsPage: FC<IProps> = () => {
  const { tag, category } = useParams<{ tag?: string, category?: string }>();
  const history = useHistory();
  const [data, setData] = useState<IContent[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState<boolean>(false);
  const page = useRef<number>(0);

  const getData = async (ev: InfiniteScrollCustomEvent | undefined = undefined) => {
    ContentsAPI.getFeed({
      limit: newsSettings.navigation.rows,
      section: "news",
      offset: page.current,
      ...(!!category && category !== 'explorar' && { category: category }),
      ...(!!tag && { tag: [tag] }),
    })
      .then((response) => {
        if (Array.isArray(response) && !!response === true) {
          setData([...(page.current === 0 ? [] : data), ...(Array.isArray(response) ? response : [])]);
          page.current = page.current + 1;
          if (response.length < newsSettings.navigation.rows)
            setIsInfiniteDisabled(true);
        } else {
          setIsInfiniteDisabled(true);
        }
        if (typeof ev !== "undefined") {
          try {
            ev && ev?.target?.complete()!;
          } catch {
            setIsInfiniteDisabled(true);
          }
        }
      })
      .catch(() => {
        /**
         * If there is an error diring the first data request,
         * user must be redirected to 404
         */
        if (data.length === 0) history.push("/404");
      });
  };

  useEffect(() => {
    setShowCategory(category === 'explorar' ? true : false);
  }, [category]);

  useEffect(() => {
    if (data.length > 0) {
      if (category === 'explorar') {
        setPageTitle(genericTitle);
      } else {
        const currentCategory = data[0].category;
        setPageTitle(currentCategory);
        if (category !== 'explorar') setMetaTags({
          title: `News - Lee las últimas noticias de ${currentCategory}`
        })
      }
    } else {
      setPageTitle('');
    }
  }, [data]);

  useEffect(() => {
    page.current = 0;
    setData([]);
    getData();
  }, [category, tag]);
  
  useEffect(() => {
    setNewsMetaTags();
    getData();
    setView();
  }, []); 
  
  return (
    <ContentWraper className="news-page" metadata={false}>
      <AdvertisementCard />
      <IonGrid fixed>
        <IonRow>
          <IonCol size='12'>
            <h1 className="page-title --news">
              {pageTitle === '' ? 
                <IonSkeletonText animated /> :
                pageTitle}</h1>
          </IonCol>
          {data.length > 0 &&
            data.map((content, index:number) => (
              <IonCol key={`${content.id}-${index}`}>
                <NewsCard content={content} showCategory={showCategory} />
              </IonCol>
            ))}
        </IonRow>
        <IonInfiniteScroll
          onIonInfinite={getData}
          threshold="50%"
          disabled={isInfiniteDisabled}
        >
          <IonRow>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
              <IonCol key={id}>
                <NewsCardSkeleton showCategory={showCategory} />
              </IonCol>
            ))}
          </IonRow>
        </IonInfiniteScroll>
      </IonGrid>

      <AdvertisementCard />
    </ContentWraper>
  );
};

export default NewsPage;
