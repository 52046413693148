import { FC, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { IonSpinner } from "@ionic/react";
import {
  ContentWraper,
  ContentsAPI,
  useAsync,
  setView
} from "shared";
import './styles.scss';
interface IProps {}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ContentsPage: FC<IProps> = () => {
  const query = useQuery();
  const history = useHistory();
  const { hashid } = useParams<{ hashid: string; }>();
  const { execute, value, error } = useAsync(ContentsAPI.get, {}, false);

  useEffect(() => {
    if (error !== null) history.push("/404");
  }, [history, error]); 

  useEffect(() => {
    const preview = query.get('preview');
    execute && hashid && execute({
      id: hashid.split('_')[0],
      ...(!!preview ? { preview: preview } : {})
    });
  }, [execute, hashid]);

  useEffect(() => {
    if (value === null) return;
    const preview = query.get('preview');
    history.push(`/${value.header.section_alias === "news" ? "leer" : "ver"}/${value.header.id}_${value.header.url_title}?preview=${!!preview ? "1" : "0"}`);
  }, [value]);

  useEffect(() => {
    setView();
  }, []);

  return (
    <ContentWraper className="contents-page">
      <IonSpinner name="circular" />
    </ContentWraper>
  );
}

export default ContentsPage;
