import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IonImg } from '@ionic/react';
import { IContent } from 'shared';
import './styles.scss';

interface IProps {
  content: IContent
}

const StoryCard: FC<IProps> = ({
  content
}) => {
  return (
    <figure className='story-card'>
      <Link
        to={`/ver/${content.id}_${content.url_title}`}
        aria-label={`Ver ${content.title}`}
        itemProp='headline'
      >
        <IonImg
          src={content.thumb || content.miniature}
          alt={`Foto: ${content.title}`}
        />
      </Link>
    </figure>
  );
}

export default StoryCard;