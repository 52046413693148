import { FC, useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonSkeletonText
} from "@ionic/react";
import {
  ContentWraper,
  MediaCard,
  MediaCardSkeleton,
  AdvertisementCard,
  IProduct,
  ProductsAPI,
  setMediaMetaTags,
  setMetaTags,
  setView
} from "shared";

interface IProps {}

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

const MediaByCategory: FC<IProps> = () => {
  const { category } = useParams<{ category?: string }>();
  const history = useHistory();
  const [data, setData] = useState<IProduct[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [isInfiniteDisabled, setIsInfiniteDisabled] = useState<boolean>(false);
  const page = useRef<number>(0);

  const getData = async (ev: InfiniteScrollCustomEvent | undefined = undefined) => {
    ProductsAPI.getFeed({
      limit: 18,
      section: "media",
      offset: page.current,
      category: category
    })
      .then((response) => {
        const { products } = response[0];
        if (Array.isArray(products) && !!products === true) {
          setData([...data, ...(Array.isArray(products) ? products : [])]);
          page.current = page.current + 1;
          if (products.length < 18) setIsInfiniteDisabled(true);
        } else {
          setIsInfiniteDisabled(true);
        }
        if (typeof ev !== "undefined") {
          try {
            ev && ev?.target?.complete()!;
          } catch {
            setIsInfiniteDisabled(true);
          }
        }
      })
      .catch(() => {
        /**
         * If there is an error diring the first data request,
         * user must be redirected to 404
         */
        if (data.length === 0) history.push("/404");
      });
  };

  useEffect(() => {
    setMediaMetaTags();
    if (data.length > 0) {
      const currentCategory = data[0].category || '';
      setPageTitle(currentCategory);
      setMetaTags({
        title: `Explora todos los programas de ${currentCategory && `${currentCategory} de `} Yok Medios`
      })
    } else {
      setPageTitle('');
    }
  }, [data]);

  useEffect(() => {
    page.current = 0;
    setData([]);
    getData();
  }, [category]);
  
  useEffect(() => {
    getData();
    setView();
  }, []); 
  
  return (
    <ContentWraper className="media-by-category-page" metadata={false}>
      <AdvertisementCard />
      <IonGrid fixed>
        <IonRow className="ion-justify-content-start">
          <IonCol size='12'>
            <h1 className="page-title --media">
              {pageTitle === '' ? 
                <IonSkeletonText animated /> :
                pageTitle}</h1>
          </IonCol>
          {data.length > 0 &&
            data.map((product, index:number) => (
              <IonCol key={`${product.id}-${index}`}>
                <MediaCard product={product} />
              </IonCol>
            ))}
        </IonRow>
        <IonInfiniteScroll
          onIonInfinite={getData}
          threshold="50%"
          disabled={isInfiniteDisabled}
        >
          <IonRow>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((id) => (
              <IonCol key={id}>
                <MediaCardSkeleton />
              </IonCol>
            ))}
          </IonRow>
        </IonInfiniteScroll>
      </IonGrid>

      <AdvertisementCard />
    </ContentWraper>
  );
};

export default MediaByCategory;
