import { FC } from 'react';
import parse from 'html-react-parser';
import SingleImage from '../SingleImage';
import {
  IContentBlock2
} from "shared";

interface IProps {
  content: IContentBlock2
}
const Block2: FC<IProps> = ({ content }) => (
  <div className='block2'>
    <div className='text-content'>
      {content?.decodedData.text && parse(content.decodedData.text)}
    </div>
    <div className='image-content'>
      {content?.decodedData.picture && <SingleImage picture={content.decodedData.picture} />}
    </div>
  </div>
)

export default Block2
