import { FC, useEffect } from 'react';
import {
  useAsync,
  ProductsAPI,
  HighlightedCard,
  HighlightedCardSkeleton
} from 'shared';
import './styles.scss';

interface IProps {}

const HighlightedNavigation: FC<IProps> = () => {
  const { execute, status, value, error } = useAsync(ProductsAPI.getHighlighted, {}, false);

  useEffect(() => {
    execute && execute({});
  }, [execute]);

  if (error !== null) return null;
  if (value !== null && value.length === 0) return null;

  return (
    <section className="highlighted-navigation">
      {status !== 'success' && [0, 1, 2, 3, 4, 5].map(item => <HighlightedCardSkeleton key={item} />)}
      {status === 'success' && value && value.map(product => <HighlightedCard key={product.id} product={product} />)}
    </section>
  );
}

export default HighlightedNavigation;